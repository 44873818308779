import axios from "axios";
import cogoToast from "cogo-toast";
import { addMinutes, format, isBefore, parseISO } from "date-fns";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Popover, Rating, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Calendar as MultiCalendar, DateObject } from "react-multi-date-picker";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";

import AddParticipantForm from "../../../components/AddParticipantForm";
import BookingLinkModal from "../../../components/BookingLinkModal";
import CancelSessionModal from "../../../components/CancelSessionModal";
import ChangeDateTimeModal from "../../../components/ChangeDateTimeModal";
import ChangeLocationModal from "../../../components/ChangeLocationModal";

import Link from "react-scroll/modules/components/Link";
import copyOrange from "../../../assets/copyOrange.svg";
import defaultImage from "../../../assets/defaultImage.svg";
import dropdownbottom from "../../../assets/dropdownbottom.svg";
import greenlocation from "../../../assets/greenlocation.svg";
import greentime from "../../../assets/greentime.svg";
import menu_schedule from "../../../assets/menu_schedule.svg";
import shareOrange from "../../../assets/shareOrange.svg";
import SessionBooking from "./SessionBooking";
import { getUrlForEvent, getUrlForUsers } from "../../../utils/confighelpers";

export default function ViewSessionsPage({
  dataToDisplay,
  sessionDataToDisplay,
  handlePopoverOpen,
  handlePopoverClose,
  popoverAnchorEl,
  infoTiles,
  isDescExpanded,
  setDescExpanded,
  popoverId,
  popoverMenuOpen,
  handleBackToDetails,
  sessionRoute,
  handleBackToHome,
  instanceId,
  updateComp,
  setUpdateComp,
  setLoading,
  backState,
  setBackState,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardReduxState = useSelector(dashboardState);
  const infoBoxes = infoTiles.slice(0, 3);
  const [isWhatToCarryExpanded, setWhatToCarryExpanded] = React.useState(false);
  const [courseStartDate, setCourseStartDate] = React.useState(new Date());
  const [courseDates, setCourseDates] = React.useState([]);
  const [showCourseDates, setShowCourseDates] = React.useState(false);
  const [sessionDetailBoxes, setSessionDetailBoxes] = React.useState([]);
  const [bookingDetails, setBookingDetails] = React.useState({
    bookedPeople: [],
    seats: 0,
    seatsBooked: 0,
    seatsBookedPerc: 0,
  });
  const [displayParticipants, setDisplayParticipants] = React.useState(false);
  const [dateTimeModalOpen, setDateTimeModalOpen] = React.useState(false);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);
  const [bookingLinkModalOpen, setBookingLinkModalOpen] = React.useState(false);
  const [scanTicketTokenModalOpen, setScanTicketTokenModalOpen] = React.useState(false);
  const [participantFormOpen, setParticipantFormOpen] = React.useState(false);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [eventDates, setEventDates] = React.useState([]);
  const [recentBookingsList, setRecentBookingsList] = React.useState([]);
  const [recentBookingsPageCount, setRecentBookingsPageCount] = React.useState(5);
  const [chooseAnotherDateToggle, setChooseAnotherDateToggle] = React.useState(false);
  const isLaptopL = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1279px)"
  );
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(min-width: 280px) and (max-width: 767px)");
  const appUrl = sessionDataToDisplay?.appUrl ? sessionDataToDisplay?.appUrl : "https://www.bookings.bookzy.me/";
  // const bookingLink = appUrl + (sessionDataToDisplay.eventId?.bookingLink ? sessionDataToDisplay.eventId?.bookingLink : "");
  const bookingLink = (sessionDataToDisplay?.bookingLink ? sessionDataToDisplay?.bookingLink : "");

  const [popoverOptionsDisabled, setPopoverOptionsDisabled] =
    React.useState(false);

  React.useEffect(() => {
    const today = Date.now();
    const { eventDate, duration, eventEndDate, participants, capacity } =
      sessionDataToDisplay;
    const parsedStartDate = parseISO(eventDate);
    setCourseStartDate(parsedStartDate);
    const endTime = addMinutes(parsedStartDate, duration);
    // const parsedEndDate = parseISO(eventEndDate);
    const startDateFormat = "d MMM, EEE, hh:mmaa";
    const endDateFormat = "hh:mmaa";

    // if (sessionDataToDisplay.isPackage) {
    const { daysInWeek, allEventSchedules, scheduleClassBookings } = sessionDataToDisplay;
    /* if (daysInWeek) {
      const mapDays = daysInWeek.map((day) => parseISO(day));
      setCourseDates([...mapDays]);
    } */
    if (allEventSchedules) {
      const mapDays = allEventSchedules.map((data) => parseISO(data.eventDate));
      setEventDates([...mapDays]);
    }
    if (scheduleClassBookings) {
      setRecentBookingsList(scheduleClassBookings);
    }

    // if (isBefore(parsedStartDate, today) || (sessionDataToDisplay?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(sessionDataToDisplay?.eventStatus?.toLowerCase()) > -1)) {
    if (moment(eventDate).subtract({ hours: 0 }).isBefore() || (sessionDataToDisplay?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(sessionDataToDisplay?.eventStatus?.toLowerCase()) > -1)) {
      setPopoverOptionsDisabled(true);
    }

    let ticketQty = 0;
    let ticketsBooked = 0;
    // scheduleClass.scheduleClassBookings
    if (sessionDataToDisplay?.eventPricingList && sessionDataToDisplay.eventPricingList.length > 0) {
      sessionDataToDisplay.eventPricingList.map((booking) => {
        ticketQty = parseInt(ticketQty) + parseInt(booking.capacity);
        ticketsBooked = parseInt(ticketsBooked) + parseInt(booking?.ticketsBooked ? booking.ticketsBooked.ticketsBookedConfirmed : 0);
      })
    } else {
      if (sessionDataToDisplay.scheduleClassBookings && sessionDataToDisplay.scheduleClassBookings.length > 0) {
        ticketQty = capacity;
        sessionDataToDisplay.scheduleClassBookings.map((booking) => {
          if (booking.status === "Cancelled") {
            return booking;
          }
          if (booking.ticketsBought && booking.ticketsBought.length > 0) {
            booking.ticketsBought.map((ticket) => {
              ticketsBooked = parseInt(parseInt(ticketsBooked) + parseInt(ticket.bookingQty))
            })
          } else {
            ticketsBooked = parseInt(ticketsBooked) + parseInt(booking.capacity ? booking.capacity : 0);
          }
        })
      }
    }

    /* setBookingDetails((prevState) => ({
      ...prevState,
      bookedPeople: participants,
      seats: capacity,
      seatsBooked: ticketQty,
      seatsBookedPerc: (ticketQty / capacity) * 100,
    })); */
    setBookingDetails((prevState) => ({
      ...prevState,
      bookedPeople: participants,
      seats: ticketQty,
      seatsBooked: ticketsBooked,
      seatsBookedPerc: (ticketsBooked / ticketQty) * 100,
    }));
  }, [sessionDataToDisplay, updateComp]);

  const handleDateTimeModalOpen = () => setDateTimeModalOpen(true);
  const handleDateTimeModalClose = () => setDateTimeModalOpen(false);

  const handleLocationModalOpen = () => setLocationModalOpen(true);
  const handleLocationModalClose = () => setLocationModalOpen(false);

  const handleBookingLinkModalOpen = () => { handlePopoverClose(); setBookingLinkModalOpen(true); }
  const handleBookingLinkModalClose = () => setBookingLinkModalOpen(false);

  const handleScanTicketTokenModalOpen = () => { handlePopoverClose(); setScanTicketTokenModalOpen(true); }
  const handleScanTicketTokenModalClose = () => setScanTicketTokenModalOpen(false);

  const handleParticpantFormOpen = () => setParticipantFormOpen(true);
  const handleParticipantFormClose = () => setParticipantFormOpen(false);

  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCloseCancelModal = () => setOpenCancelModal(false);

  const [userToken] = useLocalStorage("userToken");

  const handleCancelSession = async () => {
    setLoading(true);
    const data = {
      eventScheduleId: sessionDataToDisplay.instanceId,
    };

    const result = await axios.delete(
      // `${getUrlForEvent()}schools/deleteScheduleTheClass`,
      `${getUrlForEvent()}schedule/delete`,
      {
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": userToken,
        },
      }
    );

    if (result.data.statusCode === 200) {
      cogoToast.success("Cancelled Successfully");
      dispatch(dashboardActions.setCalendarSessionId(-1));
      dispatch(dashboardActions.setCalendarOpen(false));
      navigate("/home/dashboard");
    } else {
      cogoToast.error(result.data.message);
    }
    setLoading(false);
  };

  const [cancelText, setCancelText] = React.useState("Cancel Class");

  const onCancel = async () => {
    if (!dataToDisplay.isPackage) {
      setCancelText(
        "Canceling this event will refund respective fee for all the participants if already paid.              Are you sure you want to cancel this class"
      );
      handlePopoverClose();
      handleOpenCancelModal();
    } else {
      cogoToast.error("You cannot cancel single session of this course");
    }
  };

  const onUpdateEvent = async () => {
    navigate("/myevents/update");
  };

  const [shareLink, setShareLink] = React.useState(false);

  const onClickShareLink = async () => {
    setLoading(true);
    handlePopoverClose();

    let body = {
      eventId: dataToDisplay._id,
      isPackage: sessionDataToDisplay.isPackage,
    };

    cogoToast.loading("Loading...").then(async () => {
      const result = await axios.post(
        `${getUrlForUsers()}generateBookingViaClassLink`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": userToken,
          },
        }
      );

      if (result.data.statusCode === 200) {
        setShareLink(result.data.data.generatedUrl);
        handleBookingLinkModalOpen();
      } else {
        cogoToast.error(result.data.message);
        handleBookingLinkModalClose();
      }
    });
    setLoading(false);
  };

  const handleSessionClick = async (id) => {
    if (dashboardReduxState.calendarSessionId === id) {
      dispatch(dashboardActions.setCalendarSessionId(-1));
      dispatch(dashboardActions.setCalendarOpen(false));
      navigate("/home/dashboard");
      return;
    }
    cogoToast.loading("Loading session details");
    setLoading(true);
    const isPckg = sessionDataToDisplay.isPackage;
    const config = {
      method: "get",
      url: `${getUrlForEvent()}schedule/get?eventScheduleId=${id}&isPackage=${isPckg}`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        // dispatch(dashboardActions.setCalendarClassData(eventData));
        dispatch(dashboardActions.setCalendarSessionId(id));
        dispatch(dashboardActions.setCalendarSessionData(request.data.data));
        dispatch(dashboardActions.setViewSessionPage(true));
        // navigate("/myschedule/detail");
        window.location.reload();
      }
    }
  };

  const ticketBought = (scheduleClass, row) => {
    let ticketQty = 0;
    // scheduleClass.scheduleClassBookings
    if (scheduleClass.scheduleClassBookings && scheduleClass.scheduleClassBookings.length > 0) {
      scheduleClass.scheduleClassBookings.map((booking) => {
        if (booking.status === "Cancelled") {
          return booking;
        }
        if (booking.ticketsBought && booking.ticketsBought.length > 0) {
          booking.ticketsBought.map((ticket) => {
            if (ticket.ticketType === row.ticketType) {
              ticketQty = parseInt(parseInt(ticketQty) + parseInt(ticket.bookingQty))
            }
          })
        } else {
          ticketQty = parseInt(ticketQty) + parseInt(booking.capacity ? booking.capacity : 0);
        }
      })
    }
    return ticketQty;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      // color: theme.palette.common.white,
      fontSize: 18,
      fontWeight: "bold",
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      border: 0,
    },
    [`&.${tableCellClasses.body} div`]: {
      backgroundColor: "#FFFFFF",
      padding: "10px",
      margin: "5px 0px",
      // minHeight: "72px",
      // borderRadius: "10px",
    },
    [`&:first-child div`]: {
      borderRadius: "12px 0 0 12px",
    },
    [`&:last-child div`]: {
      borderRadius: "0 12px 12px 0",
    },
    padding: "5px 0px 5px 0px",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    margin: "10px",
    border: 0,
  }));


  // console.log("sessionDataToDisplay", sessionDataToDisplay);
  console.log("dataToDisplay", dataToDisplay);

  return (
    <div className={""}>
      {backState ? (
        <>
          <SessionBooking
            dataToDisplay={dataToDisplay}
            sessionDataToDisplay={sessionDataToDisplay}
            handlePopoverClose={handlePopoverClose}
            popoverAnchorEl={popoverAnchorEl}
            popoverId={popoverId}
            popoverMenuOpen={popoverMenuOpen}
            sessionRoute={sessionRoute}
            updateComp={updateComp}
            setLoading={setLoading}
            backState={backState}
            setBackState={setBackState}
          />
          {/* <SessionParticipants
          className={sessionDataToDisplay.eventName}
          participants={sessionDataToDisplay.participants}
          scheduleId={sessionDataToDisplay.instanceId}
          instanceId={instanceId}
          isPackage={sessionDataToDisplay.isPackage}
          handleBackToSessionDetails={() => setDisplayParticipants(false)}
          handleUpdate={() =>
            sessionRoute
              ? dispatch(
                dashboardActions.setUpdateSessionComp(
                  dashboardReduxState.updateSessionComp + 1
                )
              )
              : setUpdateComp(updateComp + 1)
          }
        /> */}
        </>
      ) : (
        <>
          <Box className="flex flex-col w-full">
            <Box className="hidden flex justify-end p-3">
              <Box className="flex-none">
                <button
                  className={"bg-[#3699FF] text-white rounded w-[160px] p-3"}
                  onClick={handlePopoverOpen}
                >
                  <span className={"flex flex-row items-center"}>
                    <p className={"w-full"}>Edit  instance</p>
                    <img src={dropdownbottom} alt={""} />
                  </span>
                </button>
              </Box>
              <Box className="hidden pl-2">
                <button
                  // disabled={popoverOptionsDisabled}
                  className={"bg-brown-coffee text-white rounded w-[210px] p-3 text-center"}
                  onClick={onUpdateEvent}
                >
                  Edit Event
                </button>
              </Box>
            </Box>
            <Box className="grid grid-cols-2 w-full py-2 px-3">
              <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                <Stack direction={"column"} spacing={3}>
                  <div className={"w-full h-[283px] flex justify-center"}>
                    {dataToDisplay.eventPhoto ? (
                      <img
                        className={
                          // "w-full h-[283px] object-cover object-fill rounded-2xl"
                          "h-[283px] rounded-2xl"
                        }
                        src={dataToDisplay.eventPhoto}
                        alt={""}
                      />
                    ) : (<img
                      className={
                        "w-full h-[283px] object-cover rounded-2xl"
                      }
                      src={defaultImage}
                      alt={""}
                    />)}
                  </div>
                  <Stack direction={"row"} alignItems={"center"}>
                    <div className={"grow"}>
                      <h2 className={"font-bold text-[30px] text-brown-coffee"}>
                        {dataToDisplay.eventName}
                      </h2>
                      <p className={"text-[15.35px]"}>
                        {sessionRoute
                          ? dataToDisplay.tagLines
                          : dataToDisplay.tagLines}
                      </p>
                      {(dataToDisplay.scheduleName !== "") ? (<h4 className={"font-bold text-[20px] text-[#FF5733]"}>{dataToDisplay.scheduleName}</h4>) : ""}
                      <div
                        className={
                          "min-w-[165px] pt-2 flex items-left bg-white rounded-lg"
                        }
                      >
                        <Stack direction={"row"} spacing={1}>
                          <Box className="pt-[2px]">
                            <Rating
                              sx={{
                                color: "#FFA800",
                                fontSize: 17,
                              }}
                              value={
                                Array.isArray(dataToDisplay.rating) ? (dataToDisplay.rating.length ? dataToDisplay.rating[0] : 0) : dataToDisplay.rating
                              }
                              precision={0.5}
                              readOnly
                            />
                          </Box>
                          <p className={"text-sm text-[#FFA800] bg-[#F4F4F4] p-[2px]"}>
                            {`${Array.isArray(dataToDisplay.rating) ? (dataToDisplay.rating.length ? dataToDisplay.rating[0] : 0) : dataToDisplay.rating}`}
                          </p>
                        </Stack>
                      </div>
                      <p className={"text-[15.35px] text-[#FF4040]"}> {(sessionDataToDisplay?.eventStatus && [/* 'Draft', 'Published',  */'cancelled', 'refunded'].indexOf(sessionDataToDisplay?.eventStatus?.toLowerCase()) > -1) ? "This Event instance is cancelled" : ""}</p>
                      <Box className="hidden flex flex-col justify-start w-full mt-5">
                        <p className={"text-[16px]"}>Share the link below to let your customer book</p>
                        <Box className="flex justify-center items-center w-full mt-5"
                          sx={{
                            backgroundColor: "#F4F4F4",
                            border: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            borderRadius: "20px",
                          }}>
                          <Box className="flex-1 pl-2 text-[#000000] my-0 mx-5 text-ellipsis overflow-hidden cursor-pointer"
                            sx={{ maxWidth: "475px", maxHeight: "25px" }}
                            onClick={() => {
                              navigator.clipboard.writeText(bookingLink);
                              cogoToast.success("Copied!");
                            }}
                          >
                            {bookingLink}
                          </Box>
                          <Box className="flex flex-none bg-brown-coffee py-2 px-5"
                            sx={{
                              borderTopRightRadius: "20px",
                              borderBottomRightRadius: "20px",
                            }}>
                            <Link className="cursor-pointer" component="button" underline="none" onClick={() => {
                              navigator.clipboard.writeText(bookingLink);
                              cogoToast.success("Copied!");
                            }}>
                              <img
                                className={"w-7"}
                                src={copyOrange}
                                alt={""}
                              />
                            </Link>
                            <Link className="cursor-pointer" component="button" underline="none" target="_blank" onClick={() => {
                              window.open(bookingLink, '_blank');
                            }}>
                              <img
                                className={"w-7 ml-3 mr-3"}
                                src={shareOrange}
                                alt={""}
                              />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction={"column"} spacing={2} className="mt-10">
                  <div className="mt-10 p-2 border-2 border-[#EBEBEB] rounded-xl">
                    <div className="grid grid-cols-2 gap-3">
                      <div
                        className={
                          "min-w-[182.74px] h-[105px] bg-white rounded-xl flex flex-col gap-4 cursor-pointer border-0 border-[#EBEBEB]"
                        }
                      >
                        <p className={"font-semibold text-sm text-brown-coffee"}>
                          Overall Bookings Status
                        </p>
                        {Array.isArray(bookingDetails.bookedPeople) &&
                          bookingDetails.bookedPeople.length > 3 ? (
                          <div
                            className={`flex flex-row items-center -space-x-2 overflow-hidden`}
                          >
                            {bookingDetails.bookedPeople.slice(0, 3).map((person, i) => (
                              <div className='avatar' key={i}>
                                <div className='rounded-full w-[40px] sm:w-8 md:w-8 lg:w-8'>
                                  <Tooltip title={person.fullName}>
                                    <img
                                      src={person.profilePic}
                                      alt={person.fullName}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            ))}
                            <div className='avatar placeholder'>
                              <div
                                className={`rounded-full font-semibold text-xs w-[40px] sm:w-8 md:w-8 lg:w-8 bg-brown-coffee text-white border-2 border-white`}
                              >
                                <span>
                                  +
                                  {bookingDetails.bookedPeople.length - 3}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : bookingDetails.bookedPeople.length < 4 ? (
                          <div
                            className={`flex flex-row items-center -space-x-2 overflow-hidden`}
                          >
                            {bookingDetails.bookedPeople.map((person) => (
                              <div className='avatar' key={person.userId}>
                                <div className='rounded-full w-[40px] sm:w-8 md:w-8 lg:w-8'>
                                  <Tooltip title={person.fullName}>
                                    <img
                                      src={person.profilePic}
                                      alt={person.fullName}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={
                          "min-w-[182.74px] h-[105px] p-4 bg-white rounded-xl flex flex-row justify-end gap-8 border-0 border-[#EBEBEB]"
                        }
                      >
                        <div
                          className={"radial-progress text-light-brown-coffee"}
                          style={{
                            "--value": Number(bookingDetails.seatsBookedPerc),
                            "--size": isMobile
                              ? "4rem"
                              : isTablet
                                ? "4rem"
                                : isLaptopL
                                  ? "4rem"
                                  : "4.75rem",
                            "--thickness": "1.5px",
                          }}
                        >
                          <div
                            className={
                              "w-[64.06px] h-[64.06px] sm:w-12 sm:h-12 md:w-12 md:h-12 lg:w-12 lg:h-12 flex justify-center items-center bg-light-brown-coffee rounded-full border-2 border-white"
                            }
                          >
                            <p className={"font-extrabold text-white text-[15px]"}>
                              {Number(bookingDetails.seatsBookedPerc).toFixed(1)}%
                            </p>
                          </div>
                        </div>
                        <div>
                          <p
                            className={
                              "font-medium text-granite-gray text-xs text-center"
                            }
                          >
                            Tickets Booked
                          </p>
                          <div className={"grid place-items-end"}>
                            <h3
                              className={
                                "text-light-brown-coffee font-bold text-base sm:text-sm md:text-sm lg:text-sm"
                              }
                            >
                              {bookingDetails.seatsBooked}/{bookingDetails.seats}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Box>
                      <p className={"font-semibold text-base text-brown-coffee"}>
                        Bookings by ticket type
                      </p>
                      <Box
                        sx={{
                          p: 2,
                          mt: 1,
                          backgroundColor: "#F4F4F4",
                          borderRadius: "15px",
                        }}>

                        <TableContainer>
                          <Table sx={{}} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell width="35%" align="left">Ticket Type</StyledTableCell>
                                <StyledTableCell width="30%" align="center">Price</StyledTableCell>
                                <StyledTableCell width="35%" align="center">Tickets booked</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sessionDataToDisplay.eventPricingList && sessionDataToDisplay.eventPricingList.map((row, i) => (
                                <StyledTableRow key={i} >
                                  <StyledTableCell align="center"><Box>{row.ticketType}</Box></StyledTableCell>
                                  <StyledTableCell align="center"><Box>€{row.amount}</Box></StyledTableCell>
                                  <StyledTableCell align="center"><Box>{/* {ticketBought(sessionDataToDisplay, row)} */} {row.ticketsBooked?.ticketsBookedConfirmed ? row.ticketsBooked.ticketsBookedConfirmed : ticketBought(sessionDataToDisplay, row)}/{row.capacity}</Box></StyledTableCell>

                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                    <Box>
                      <p className={"font-semibold text-base text-brown-coffee mt-10"}>
                        Bookings by addons type
                      </p>
                      <Box
                        sx={{
                          p: 2,
                          mt: 1,
                          backgroundColor: "#F4F4F4",
                          borderRadius: "15px",
                        }}>

                        <TableContainer>
                          <Table sx={{}} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell width="35%" align="left">Addons Name</StyledTableCell>
                                <StyledTableCell width="30%" align="center">Price</StyledTableCell>
                                <StyledTableCell width="35%" align="center">Addons booked</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sessionDataToDisplay.addonsPricingList && sessionDataToDisplay.addonsPricingList.map((row, i) => (
                                <StyledTableRow key={i} >
                                  <StyledTableCell align="center"><Box>{row.addonName}</Box></StyledTableCell>
                                  <StyledTableCell align="center"><Box>€{row.amountForTicket}</Box></StyledTableCell>
                                  <StyledTableCell align="center"><Box> {row.addonsBooked?.addonsBookedConfirmed ? row.addonsBooked.addonsBookedConfirmed : 0}</Box></StyledTableCell>

                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Box>
                  </div>
                  <div className={"flex flex-row justify-end p-1"}>
                    <Link className="hidden text-[#5265FF] cursor-pointer" component="button" underline="none" onClick={() => setBackState(true)}>Goto All bookings</Link>
                  </div>
                </Stack>
                {false ? (
                  <Stack direction={"column"} spacing={3} sx={{ mb: "40px !important" }}>
                    <div className="mt-5">
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        Description
                      </h4>
                      <p className={"font-light text-base"}>
                        {sessionRoute ? (
                          dataToDisplay?.description > 175 ? (
                            !isDescExpanded ? (
                              <>
                                {dataToDisplay?.description.slice(0, 175)}
                                <span
                                  className={
                                    "text-gray-400 font-light text-base cursor-pointer"
                                  }
                                  onClick={() => setDescExpanded(true)}
                                >
                                  ...see more
                                </span>
                              </>
                            ) : (
                              <span>
                                {dataToDisplay?.description}
                                <br />
                                <span
                                  className={
                                    "text-gray-400 font-light text-base cursor-pointer"
                                  }
                                  onClick={() => setDescExpanded(false)}
                                >
                                  see less
                                </span>
                              </span>
                            )
                          ) : (
                            <span>{dataToDisplay?.description}</span>
                          )
                        ) : dataToDisplay.description?.length > 175 ? (
                          !isDescExpanded ? (
                            <>
                              {dataToDisplay.description.slice(0, 175)}
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setDescExpanded(true)}
                              >
                                ...see more
                              </span>
                            </>
                          ) : (
                            <span>
                              {dataToDisplay.description}
                              <br />
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setDescExpanded(false)}
                              >
                                see less
                              </span>
                            </span>
                          )
                        ) : (
                          <span>{dataToDisplay.description}</span>
                        )}
                      </p>
                    </div>
                    <div>
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        What To Carry
                      </h4>
                      <p className={"font-light text-base"}>
                        {sessionRoute ? (
                          dataToDisplay?.whatToCarry?.length > 175 ? (
                            !isWhatToCarryExpanded ? (
                              <>
                                {dataToDisplay?.whatToCarry?.slice(0, 175)}
                                <span
                                  className={
                                    "text-gray-400 font-light text-base cursor-pointer"
                                  }
                                  onClick={() => setWhatToCarryExpanded(true)}
                                >
                                  ...see more
                                </span>
                              </>
                            ) : (
                              <span>
                                {dataToDisplay?.whatToCarry}
                                <br />
                                <span
                                  className={
                                    "text-gray-400 font-light text-base cursor-pointer"
                                  }
                                  onClick={() => setWhatToCarryExpanded(false)}
                                >
                                  see less
                                </span>
                              </span>
                            )
                          ) : (
                            <span>{dataToDisplay?.whatToCarry}</span>
                          )
                        ) : dataToDisplay.whatToCarry?.length > 175 ? (
                          !isWhatToCarryExpanded ? (
                            <>
                              {dataToDisplay.whatToCarry.slice(0, 175)}
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setWhatToCarryExpanded(true)}
                              >
                                ...see more
                              </span>
                            </>
                          ) : (
                            <span>
                              {dataToDisplay.whatToCarry}
                              <br />
                              <span
                                className={
                                  "text-gray-400 font-light text-base cursor-pointer"
                                }
                                onClick={() => setWhatToCarryExpanded(false)}
                              >
                                see less
                              </span>
                            </span>
                          )
                        ) : (
                          <span>{dataToDisplay.whatToCarry}</span>
                        )}
                      </p>
                    </div>
                    <div>
                      <h4 className={"font-bold text-lg text-brown-coffee"}>
                        Cancelation Policy
                      </h4>
                      {sessionRoute ? (
                        dataToDisplay?.eventCancelationPolicy?.isEnabled ? (
                          <p className={"font-light text-base"}>
                            Cancelation is free in case booking is cancelled{" "}
                            <span className={"font-bold"}>
                              {dataToDisplay?.eventCancelationPolicy?.text}
                            </span>{" "}
                            before start of the class.
                          </p>
                        ) : (
                          <p className={"font-light text-base"}>
                            No Cancelation Policy available.
                          </p>
                        )
                      ) : dataToDisplay?.eventCancelationPolicy?.isEnabled ? (
                        <p className={"font-light text-base"}>
                          Cancelation is free in case booking is cancelled{" "}
                          <span className={"font-bold"}>
                            {dataToDisplay.eventCancelationPolicy.text}
                          </span>{" "}
                          before start of the{" "}
                          {dataToDisplay.isPackage ? "course" : "class"}.
                        </p>
                      ) : (
                        <p className={"font-light text-base"}>
                          No Cancelation Policy available.
                        </p>
                      )}
                    </div>
                  </Stack>
                ) : ""}
              </Stack>
              <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                <Box className={`grid grid-cols-1 w-full`}>
                  <Box className="flex flex-col justify-center items-left ">
                    <Box className="flex justify-center items-center w-full py-3">
                      <Box className="flex flex-col justify-start w-full mt-5">
                        <p className={"text-[16px] font-extrabold"}>Share event Link</p>
                        <Box className="flex justify-center items-center w-full mt-5"
                          sx={{
                            backgroundColor: "#F4F4F4",
                            border: 1,
                            borderColor: "rgba(0, 0, 0, 0.14)",
                            borderRadius: "12px",
                          }}>
                          <Box className="flex-1 pl-2 text-[#000000] my-0 mx-5 text-ellipsis overflow-hidden cursor-pointer break-all"
                            sx={{ /* maxWidth: "475px", */ maxHeight: "25px" }}
                            onClick={() => {
                              navigator.clipboard.writeText(bookingLink);
                              cogoToast.success("Copied!");
                            }}
                          >
                            {bookingLink}
                          </Box>
                          <Box className="flex flex-none bg-brown-coffee py-2 px-8"
                            sx={{
                              borderTopRightRadius: "12px",
                              borderBottomRightRadius: "12px",
                            }}>
                            <Link className="cursor-pointer" component="button" underline="none" onClick={() => {
                              navigator.clipboard.writeText(bookingLink);
                              cogoToast.success("Copied!");
                            }}>
                              <img
                                className={"w-5"}
                                src={copyOrange}
                                alt={""}
                              />
                            </Link>
                            <Link className="cursor-pointer" component="button" underline="none" target="_blank" onClick={() => {
                              window.open(bookingLink, '_blank');
                            }}>
                              <img
                                className={"w-5 ml-3 mr-0"}
                                src={shareOrange}
                                alt={""}
                              />
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="flex justify-center items-center w-4/5 py-3">
                      <Box className="flex-none">
                        <img
                          className={""}
                          src={greenlocation}
                          alt={""}
                        />
                      </Box>
                      <Box className="flex-1 pl-2">
                        <div className="text-[#00000099] text-sm">Where </div>
                        <div className="text-[#000000] text-base">
                          <p className="font-bold">{sessionDataToDisplay && sessionDataToDisplay.location ? sessionDataToDisplay.location.addressName : ""}</p>
                          <p>{sessionDataToDisplay && sessionDataToDisplay.location ? sessionDataToDisplay.location.address : ""}</p>
                        </div>
                      </Box>
                    </Box>
                    <Box className="flex justify-center items-center w-4/5 py-3">
                      <Box className="flex-none">
                        <img
                          className={""}
                          src={greentime}
                          alt={""}
                        />
                      </Box>
                      <Box className="flex-1 pl-2">
                        <div className="text-[#00000099] text-sm">When </div>
                        <div className="text-[#000000] text-base">
                          {sessionDataToDisplay.eventDate ? format(parseISO(sessionDataToDisplay.eventDate), "EEEE, MMMM d 'at' hh:mm aa") + " - " + format(parseISO(sessionDataToDisplay.eventEndDate), "hh:mm aa") : ""}
                        </div>
                        <div className="text-[#00000099] text-xs">
                          {sessionDataToDisplay.allEventSchedules.length > 1 ? "This Event has multiple instances" : ""}
                        </div>
                      </Box>
                    </Box>
                    {/* <Box className="flex justify-center items-center w-4/5 py-3">
                    <Box className="flex-none">
                      <img
                        className={""}
                        src={greenprice}
                        alt={""}
                      />
                    </Box>
                    <Box className="flex-1 pl-2">
                      <div className="text-[#00000099] text-sm">Price </div>
                      <div className="text-[#000000] text-base">
                        <p>€{sessionDataToDisplay.eventPricingList && sessionDataToDisplay.eventPricingList.length ? sessionDataToDisplay.eventPricingList[0].amount : (sessionDataToDisplay.cost ? sessionDataToDisplay.cost : 0)}
                          {sessionDataToDisplay.eventPricingList && sessionDataToDisplay.eventPricingList.length > 1 ? " -- €" + sessionDataToDisplay.eventPricingList[sessionDataToDisplay.eventPricingList.length - 1].amount + " (Multiple Tickets)" : ""}</p>
                      </div>
                    </Box>
                  </Box> */}
                    <Box className="flex py-3 gap-3">
                      <Box className="flex-none">
                        <button
                          className={"bg-[#3699FF] text-white rounded-lg w-[160px] p-3"}
                          onClick={handlePopoverOpen}
                        >
                          <span className={"flex flex-row items-center"}>
                            <p className={"w-full"}>Actions</p>
                            <img src={dropdownbottom} alt={""} />
                          </span>
                        </button>
                      </Box>
                      {sessionDataToDisplay.allEventSchedules.length > 1 ? (<Box className="flex flex-col justify-start items-left">

                        <Box>
                          <Box className="flex-none">
                            <button
                              className={"bg-[#F64E60] text-white rounded-lg w-[326px] p-3 px-6"}
                              onClick={() => { setChooseAnotherDateToggle(!chooseAnotherDateToggle); }}
                            >
                              <span className={"flex flex-row items-center"}>
                                <img src={menu_schedule} alt={""} />
                                <p className={"w-full"}>Choose another instance</p>
                                <img src={dropdownbottom} alt={""} />
                              </span>
                            </button>
                          </Box>
                        </Box>
                        {chooseAnotherDateToggle ? (<Box className="flex-none py-5">

                          <MultiCalendar
                            className="bookzy-calendar preview-click"
                            currentDate={new DateObject(sessionDataToDisplay.eventDate)}
                            weekDays={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
                            // readOnly
                            single
                            onChange={(dates) => {
                              console.log(dates);

                              const dateTransformed = moment(dates.toDate()).format("MM/DD/YYYY");
                              const selectedDatesTransformed = eventDates ? eventDates.map(
                                (day) => moment(day).format("MM/DD/YYYY")
                              ) : [];
                              let selectindex = selectedDatesTransformed.indexOf(dateTransformed);
                              // let selectindex = sessionDataToDisplay.allEventSchedules.find(o => moment(o.eventDate).isSame(moment(dateTransformed).format("MM/DD/YYYY")));
                              console.log("selectindex", selectindex);

                              if (selectindex > -1) {
                                if (!moment(dateTransformed).isSame(moment(sessionDataToDisplay.eventDate).format("MM/DD/YYYY"))) {
                                  console.log("not same");
                                  console.log(sessionDataToDisplay.allEventSchedules[selectindex]);
                                  handleSessionClick(sessionDataToDisplay.allEventSchedules[selectindex]._id);
                                } else { console.log("same"); }
                              }

                              // setCourseDates([...courseDates]);
                              // handleSessionClick();
                              return false;
                            }}
                            mapDays={({
                              date,
                              today,
                              selectedDate,
                              currentMonth,
                              isSameDate,
                            }) => {
                              let props = {};

                              // const dateTransformed = date.toDate();
                              const dateTransformed = moment(date.toDate()).format("MM/DD/YYYY");
                              /* const selectedDatesTransformed = selectedDate ? selectedDate.map(
                                // (day) => day.toDate()
                                (day) => moment(day.toDate()).format("MM/DD/YYYY")
                              ) : []; */
                              const selectedDatesTransformed = eventDates ? eventDates.map(
                                // (day) => day.toDate()
                                (day) => moment(day).format("MM/DD/YYYY")
                              ) : [];
                              // console.log(moment(date.toDate()).format("MM/DD/YYYY") + "===" + moment(today).format("MM/DD/YYYY") + "= " + (moment(moment(date.toDate()).format("MM/DD/YYYY")).isSame(moment(today).format("MM/DD/YYYY"))));
                              // console.log("isSameDate", isSameDate);
                              // console.log("dateTransformed", dateTransformed + " ==  " + selectedDatesTransformed.indexOf(dateTransformed));
                              // console.log("selectedDatesTransformed", selectedDatesTransformed);
                              /* if (moment(moment(date.toDate()).format("MM/DD/YYYY")).isSame(moment(today).format("MM/DD/YYYY"))) {
                                props.className = "default-current-date";
                              } */

                              if (selectedDatesTransformed.indexOf(dateTransformed) > -1) {
                                props.className = "default-class-dates";
                              }

                              if (moment(moment(date.toDate()).format("MM/DD/YYYY")).isSame(moment(sessionDataToDisplay.eventDate).format("MM/DD/YYYY"))) {
                                props.className = "default-selected-date";
                              }

                              return props;
                            }}
                            style={{
                              backgroundColor: "text-brown-coffee",
                              // height: "24px",
                              borderRadius: "8px",
                              fontSize: "24px",
                              padding: "3px 10px"
                            }}
                          />
                        </Box>) : ""}
                      </Box>) : ""}
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box>
              <SessionBooking
                dataToDisplay={dataToDisplay}
                sessionDataToDisplay={sessionDataToDisplay}
                handlePopoverClose={handlePopoverClose}
                popoverAnchorEl={popoverAnchorEl}
                popoverId={popoverId}
                popoverMenuOpen={popoverMenuOpen}
                sessionRoute={sessionRoute}
                updateComp={updateComp}
                setLoading={setLoading}
                backState={backState}
                setBackState={setBackState}
                displayHeader={false}
              />
            </Box>
          </Box>
          <Popover
            id={popoverId}
            open={popoverMenuOpen}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              my: 1,
              "& .MuiPopover-paper": {
                boxShadow: "none",
                border: 1,
                borderColor: "#EBEBEB",
                borderRadius: 3,
              },
            }}
          >
            <div className={"flex flex-col gap-3 py-5"}>
              <button
                disabled={popoverOptionsDisabled}
                onClick={() => {
                  dispatch(dashboardActions.setCalendarClassData(sessionDataToDisplay.eventId));
                  dispatch(dashboardActions.setCalendarSessionId(sessionDataToDisplay.eventId._id));
                  dispatch(dashboardActions.setCalendarSessionData({ eventId: sessionDataToDisplay.eventId, allEventSchedules: sessionDataToDisplay.allEventSchedules }));
                  dispatch(dashboardActions.setViewSessionPage(true));
                  navigate("/myevents/detail");
                }}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Edit Event Details
                </p>
              </button>
              <button
                disabled={popoverOptionsDisabled}
                onClick={() => {
                  handlePopoverClose();
                  handleDateTimeModalOpen();
                }}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Change Schedule
                </p>
              </button>
              <button
                disabled={popoverOptionsDisabled}
                onClick={() => {
                  handlePopoverClose();
                  handleLocationModalOpen();
                }}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Update Location
                </p>
              </button>
              <button
                // disabled={popoverOptionsDisabled}
                disabled={true}
                onClick={() => {
                  handlePopoverClose();
                  handleParticpantFormOpen();
                }}
              >
                <p
                  // className={`${popoverOptionsDisabled
                  className={`${true
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Add Participant
                </p>
              </button>
              {/* <button disabled={popoverOptionsDisabled} onClick={onCancel}>
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Cancel
                </p>
              </button> */}
              <button
                disabled={popoverOptionsDisabled}
                // onClick={onClickShareLink}
                onClick={handleBookingLinkModalOpen}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Share Booking Link
                </p>
              </button>
              <button
                disabled={popoverOptionsDisabled}
                // onClick={onClickShareLink}
                onClick={handleScanTicketTokenModalOpen}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Share Scan Ticket Token
                </p>
              </button>
              <button
                disabled={popoverOptionsDisabled}
                onClick={onCancel}
              >
                <p
                  className={`${popoverOptionsDisabled
                    ? "text-lilac"
                    : "text-light-brown-coffee"
                    } text-sm text-left`}
                >
                  Cancel Event
                </p>
              </button>
            </div>
          </Popover>
          <AddParticipantForm
            open={participantFormOpen}
            handleClose={handleParticipantFormClose}
            isPackage={dataToDisplay.isPackage}
            scheduleId={
              dataToDisplay.isPackage
                ? sessionDataToDisplay.packageId
                : sessionDataToDisplay.instanceId
            }
            handleUpdate={() =>
              sessionRoute
                ? dispatch(
                  dashboardActions.setUpdateSessionComp(
                    dashboardReduxState.updateSessionComp + 1
                  )
                )
                : setUpdateComp(updateComp + 1)
            }
          />
          <ChangeDateTimeModal
            open={dateTimeModalOpen}
            handleClose={handleDateTimeModalClose}
            sessionData={sessionDataToDisplay}
            instanceId={instanceId}
            handleUpdate={() =>
              sessionRoute
                ? dispatch(
                  dashboardActions.setUpdateSessionComp(
                    dashboardReduxState.updateSessionComp + 1
                  )
                )
                : setUpdateComp(updateComp + 1)
            }
          />
          <ChangeLocationModal
            open={locationModalOpen}
            handleClose={handleLocationModalClose}
            sessionData={sessionDataToDisplay}
            instanceId={instanceId}
            handleUpdate={() =>
              sessionRoute
                ? dispatch(
                  dashboardActions.setUpdateSessionComp(
                    dashboardReduxState.updateSessionComp + 1
                  )
                )
                : setUpdateComp(updateComp + 1)
            }
          />
          <CancelSessionModal
            open={openCancelModal}
            handleClose={handleCloseCancelModal}
            text={cancelText}
            handleDelete={handleCancelSession}
          />
          <BookingLinkModal
            title={"Booking link for " + dataToDisplay?.eventName}
            subtitle="Booking Link Generated"
            open={bookingLinkModalOpen}
            handleClose={handleBookingLinkModalClose}
            bookingLink={bookingLink}
          />
          <BookingLinkModal
            title={"Scan Ticket token for " + dataToDisplay?.eventName}
            // subtitle="Scan Ticket Link Generated"
            subtitle=""
            open={scanTicketTokenModalOpen}
            handleClose={handleScanTicketTokenModalClose}
            bookingLink={sessionDataToDisplay?.scanTicketToken || ""}
          />
        </>
      )}
    </div>
  );
}
