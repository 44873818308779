import {
  Box, Button,
  FormControl,
  FormHelperText, FormLabel, InputBase, MenuItem, Select, Stack
} from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { eachMinuteOfInterval, format, sub } from "date-fns";
import React from "react";
import Dropzone from "react-dropzone";
import { usePopper } from "react-popper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import defaultImage from "../../../assets/defaultImage.svg";
import uploadImage from "../../../assets/uploadImage.svg";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import { handleUploadImageToS3 } from "../../../helpers";
import { useLocalStorage } from "../../../hooks";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { schoolState } from "../../../redux/reducers/schoolReducer";
import { getUrlForUsers } from "../../../utils/confighelpers";

const popperModifiers = { placement: "bottom" };

export default function GeneralTab({
  title,
  openSuccessModal,
  edit,
  editData,
  setLoading,
  formValues,
  setFormValues,
  formValidationErrors,
  setTabCurrentState,
  handleUpdate,
  handleCancel,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userToken] = useLocalStorage("userToken");
  const dashboardReduxState = useSelector(dashboardState);
  const schoolReduxState = useSelector(schoolState);
  const sessionDataToDisplay = dashboardReduxState.calendarSessionData ? dashboardReduxState.calendarSessionData : {};
  const eventInfo = sessionDataToDisplay?.eventId ? sessionDataToDisplay.eventId : {};
  const [eventcategoryOpts, setEventCategoryOpts] = React.useState([]);
  const [categoryOpts, setCategoryOpts] = React.useState([]);
  const [sessionsOpts, setSessionsOpts] = React.useState([]);
  const [durationOpts, setDurationOpts] = React.useState([]);
  const [seatsOpts, setSeatsOpts] = React.useState([]);

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [catRefEl, setCatRefEl] = React.useState();
  const [catPopperEl, setCatPopperEl] = React.useState();
  const catPopper = usePopper(catRefEl, catPopperEl, popperModifiers);
  const [sesRefEl, setSesRefEl] = React.useState();
  const [sesPopperEl, setSesPopperEl] = React.useState();
  const sesPopper = usePopper(sesRefEl, sesPopperEl, popperModifiers);
  const [durRefEl, setDurRefEl] = React.useState();
  const [durPopperEl, setDurPopperEl] = React.useState();
  const durPopper = usePopper(durRefEl, durPopperEl, popperModifiers);
  const [seatsRefEl, setSeatsRefEl] = React.useState();
  const [seatsPopperEl, setSeatsPopperEl] = React.useState();
  const seatsPopper = usePopper(seatsRefEl, seatsPopperEl, popperModifiers);


  const [categories, setCategories] = React.useState([]);
  const [openSelectCategoryModal, setOpenSelectCategoryModal] =
    React.useState(false);
  const [openOtpModal, setOpenOtpModal] = React.useState(false);
  const [imageProgress, setImageProgress] = React.useState(0);

  const location = useLocation();
  React.useEffect(() => {
    let sesOpts = [];
    let durOpts = [];
    let seatsOptions = [];
    let catOpts = [...schoolReduxState.schoolCategories];
    for (let i = 1; i < 101; i++) {
      sesOpts.push(String(i));
      seatsOptions.push(String(i));
    }

    let intervalOptions = {
      start: new Date(Date.now()),
      end: new Date(Date.now()),
    };

    intervalOptions.start = intervalOptions.start.setMinutes(15);
    intervalOptions.start = new Date(intervalOptions.start).setHours(0);

    intervalOptions.end = intervalOptions.end.setMinutes(0);
    intervalOptions.end = new Date(intervalOptions.end).setHours(8);

    durOpts = eachMinuteOfInterval(intervalOptions, { step: 15 });
    durOpts = durOpts.map((opt) =>
      format(sub(opt, { hours: 0 }), "K 'hr' m 'min'")
    );

    // setCategoryOpts([...catOpts]);
    setSessionsOpts([...sesOpts]);
    setDurationOpts([...durOpts]);
    setSeatsOpts([...seatsOptions]);
  }, [schoolReduxState]);

  React.useEffect(() => {
    if (formValues.freeCancellation === false) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        cancellationPeriod: "",
      }));
    }
  }, [formValues.freeCancellation]);

  React.useEffect(() => {
    (async () => {
      /* const config = {
        method: "get",
        url: `${getUrlForUsers()}getCategoryGroup`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        }
      };
      const request = await axios(config);
      setCategoryOpts(request.data.data);
      if (edit && request.data?.data?.length) {
        const allCategories = request.data.data.map((item) => [...item.categories]).flat()
        const selectedCat = allCategories.find(catItem => catItem.catId === eventInfo.catId)

        setFormValues((prevValues) => ({
          ...prevValues,
          categories: selectedCat,
        }));
      } */
      const config = {
        method: "get",
        url: `${getUrlForUsers()}categories/get?businesType=event`,
        headers: {
          "x-access-token": userToken.toString(),
          "Access-Control-Allow-Headers": "*",
        }
      };
      const request = await axios(config);
      setEventCategoryOpts(request.data.data);
      // setCategoryOpts(request.data.data);
      if (edit && request.data?.data?.length) {
        const allEventCategories = request.data.data.map((item) => [...item.category]).flat()
        const selectedeventCategory = allEventCategories.find(catItem => {
          return (catItem._id === eventInfo.eventCategory)
        })
        setFormValues((prevValues) => ({
          ...prevValues,
          eventCategory: selectedeventCategory,
        }));

        const selectedCat = selectedeventCategory.category.find(catItem => {
          return (catItem._id === eventInfo.catId)
        })
        setCategoryOpts(selectedeventCategory?.category ? selectedeventCategory.category : []);
        setFormValues((prevValues) => ({
          ...prevValues,
          categories: selectedCat,
        }));
      }
    })();
  }, []);

  React.useEffect(() => {
    if (formValues.eventCategory !== undefined && formValues.eventCategory !== "" && eventcategoryOpts.length > 0) {
      const selectedeventCat = eventcategoryOpts[0].category.find(catItem => {
        return (catItem._id === formValues.eventCategory._id)
      })

      setFormValues((prevValues) => ({
        ...prevValues,
        eventCategory: selectedeventCat,
      }));

      if (selectedeventCat && selectedeventCat.category && selectedeventCat.category.length > 0) {
        const selectedCat = selectedeventCat.category.find(catItem => {
          return (catItem._id === formValues.categories._id)
        })
        setCategoryOpts(selectedeventCat.category);
        setFormValues((prevValues) => ({
          ...prevValues,
          categories: selectedCat,
        }));
      }
    }
  }, [eventcategoryOpts]);


  const handleFormValueChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    // cogoToast.loading("Loading...");
    // setLoading(true);
    const errors = {};
    if (!formValues.photo) {
      errors.photo = "Required!";
    }
    if (!Object.keys(formValues.eventCategory).length) {
      errors.eventCategory = "Required!";
    }
    if (!Object.keys(formValues.categories).length) {
      errors.categories = "Required!";
    }
    if (!formValues.eventName) {
      errors.eventName = "Required!";
    }
    if (!formValues.classDescription) {
      errors.classDescription = "Required!";
    } else if (formValues.classDescription.length < 5) {
      errors.classDescription = `Enter at least 5 letters to explain your ${title}`;
    }
    /* if (!formValues.price) {
      errors.price = "Required";
    }
    if (!formValues.seats) {
      errors.seats = "Required";
    } */
    if (!formValues.duration) {
      errors.duration = "Required";
    }
    if (title === "Course" && !formValues.sessions) {
      errors.sessions = "Required!";
    }
    /* if (formValues.freeCancellation) {
      if (!formValues.cancellationPeriod) {
        errors.cancellationPeriod = "Required!";
      }
    } */
    setFormErrors({ ...errors });
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      setSubmitting(false);
      cogoToast.error("Please fix errors below", { hideAfter: 3 });
      return;
    }

    if (!formValues.whatToCarry) {
      setFormValues((prevValues) => ({
        ...prevValues,
        whatToCarry: "Nothing specified",
      }));
    }

    const durationSplit = formValues.durationstring.split(" ");
    const durationInNum =
      Number(durationSplit[0]) * 60 + Number(durationSplit[2]);
    setFormValues((prevValues) => ({
      ...prevValues,
      duration: durationInNum,
    }));

    setSubmitting(false);
    setLoading(false);
    setTabCurrentState("2");

  };

  const clearFormValues = () => {
    setFormValues({
      photo: "",
      categories: "",
      eventName: "",
      tagLine: "",
      classDescription: "",
      price: "",
      seats: "",
      duration: "",
      sessions: "",
      whatToCarry: "",
      freeCancellation: true,
      cancellationPeriod: "24hrs",
      inviteCodeOnlyBooking: false,
      paymentAtVenue: false,
    });
  };

  const handleOpenSelectCategoryModal = () => setOpenSelectCategoryModal(true);
  const handleCloseSelectCategoryModal = () =>
    setOpenSelectCategoryModal(false);
  const handleOpenOtpModal = () => setOpenOtpModal(true);
  const handleCloseOtpModal = () => setOpenOtpModal(false);

  const handleAddCategory = (cat) => {
    let catsCopy = Array.isArray(categories) ? [...categories] : [];
    Array.isArray(catsCopy) && catsCopy.indexOf(cat) === -1
      ? (catsCopy = [...catsCopy, cat])
      : (catsCopy = catsCopy.filter((item) => item !== cat));
    setCategories(catsCopy);
    setFormValues((prevValues) => ({
      ...prevValues,
      categories: catsCopy,
    }));
  };

  const handleRemoveCategory = (cat) => {
    let catsCopy = [...categories];
    if (Array.isArray(catsCopy)) {
      catsCopy = catsCopy.filter((item) => item !== cat);
    }
    setCategories(catsCopy);
    setFormValues((prevValues) => ({
      ...prevValues,
      categories: catsCopy,
    }));
  };

  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 2,
    // border: 1,
    borderRadius: 3,
    // borderColor: formErrors["className"] ? "#d32f2f" : "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    minWidth: 696,
    maxWidth: 696,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#424242",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  React.useEffect(() => {
    if (formValidationErrors) {
      setFormErrors(formValidationErrors)
    }
  }, [formValidationErrors]);

  return (
    <Stack direction={"column"} className={"py-6 px-8"} spacing={2}>
      <Dropzone
        accept="image/png, image/jpeg"
        noDrag={true}
        onDrop={async (files) => {
          const errors = {};
          if (files[0]) {
            setImageProgress(Math.floor((Math.random() * 30) + 1));
            const file = await handleUploadImageToS3(files[0]);
            setImageProgress(100);
            if (file.result.ok) {
              setFormValues(prevValues => ({
                ...prevValues,
                photo: file.location,
              }))
            }
            setImageProgress(100);
          } else {
            setFormValues(prevValues => ({
              ...prevValues,
              photo: "",
            }))
            errors.photo = "Only PNG and JPG formats are allowed";
          }
          setFormErrors({ ...errors });
        }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps({ className: "dropzone" })}
              className={"max-h-[165px]"}
            >
              <div
                className={`p-2 border-dashed border-2 min-w-[696px] max-w-[696px] min-h-[160px] rounded-xl flex flex-col justify-center items-center ${formValues.photo ? "border-0" : "bg-white"
                  } ${formErrors.photo ? "border-red-500" : ""}`}
              >
                <div className={`grid grid-cols-2 w-full`}>
                  {formValues.photo ? (
                    <img
                      className={
                        // "min-w-[696px] max-w-[696px] min-h-[160px] max-h-[160px] rounded-xl object-cover"
                        "max-w-[340px] max-h-[160px] rounded-xl object-cover"
                      }
                      src={formValues.photo}
                      alt={`Your ${title} Cover`}
                    />
                  ) : (<img
                    className={
                      // "min-w-[696px] max-w-[696px] min-h-[160px] max-h-[160px] rounded-xl object-cover"
                      "max-w-[696px] max-h-[160px] rounded-xl object-cover"
                    }
                    src={defaultImage}
                    alt={`Your ${title} Cover`}
                  />)}
                  <Box className="flex flex-col justify-center items-center ">
                    <Box className="flex justify-center items-center w-4/5">
                      <Box className="flex-none">
                        <img
                          className={""}
                          src={uploadImage}
                          alt={""}
                        />
                      </Box>
                      <Box className="flex-1 pl-2">
                        <div className="text-[#33BE97]">Click here </div>
                        <div>
                          to upload Image
                        </div>
                      </Box>
                    </Box>
                    <Box className="w-full" sx={{ color: "#33BE97" }}>
                      <LinearProgressWithLabel color="progress" variant="determinate" value={imageProgress} />
                    </Box>
                  </Box>
                </div>
              </div>
              <input {...getInputProps()} />
            </div>
          );
        }}
      </Dropzone>
      {formErrors["photo"] && (
        <FormHelperText error={formErrors?.photo != ""}>{formErrors["photo"]}</FormHelperText>
      )}
      <Stack direction={"column"} spacing={2}>
        <Stack spacing={2}>
          <FormControl error={formErrors["eventName"] ? true : false}>
            <FormLabel sx={formLableStyle}>Title</FormLabel>
            <InputBase
              name={"eventName"}
              // placeholder={"Title"}
              value={formValues.eventName}
              inputProps={{ maxLength: 30 }}
              onChange={handleFormValueChange}
              classes={formInputClasses}
              sx={formInputStyle}
            />
            {formErrors["eventName"] && (
              <FormHelperText>{formErrors["eventName"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={false}>
            <FormLabel sx={formLableStyle}>Tag Line</FormLabel>
            <InputBase
              name={"tagLine"}
              // placeholder={"Tag line (Optional)"}
              value={formValues.tagLine}
              onChange={handleFormValueChange}
              inputProps={{ maxLength: 30 }}
              classes={formInputClasses}
              sx={formInputStyle}
            />
            {formErrors["tagLine"] && <FormHelperText></FormHelperText>}
          </FormControl>
          <FormControl error={formErrors["eventCategory"] ? true : false}>
            <FormLabel sx={formLableStyle}>Event Type</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {/* <img src={select_start_adornment} alt={""} /> */}
                  {value.name || (
                    <span className={"font-light text-plain-gray"}>
                      Select Event Type
                    </span>
                  )}
                </div>
              )}
              value={formValues.eventCategory}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  eventCategory: event.target.value,
                  categories: "",
                }));
                console.log("event.target.value.category", event.target.value.category);
                if (event.target.value.category) {
                  setCategoryOpts(event.target.value.category);
                }
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {eventcategoryOpts.map((categorygroup, idx) => (
                categorygroup.category.map((category, idx) => (
                  <MenuItem value={category} key={idx}>
                    {category.name}
                  </MenuItem>
                ))
              ))}
            </Select>
            {formErrors["eventCategory"] && (
              <FormHelperText>{formErrors["eventCategory"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["categories"] ? true : false}>
            <FormLabel sx={formLableStyle}>Category</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {/* <img src={select_start_adornment} alt={""} /> */}
                  {value.name || (
                    <span className={"font-light text-plain-gray"}>
                      Select Category
                    </span>
                  )}
                </div>
              )}
              value={formValues.categories}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  categories: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {categoryOpts.map((category, idx) => (
                <MenuItem value={category} key={idx}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {/* <div className={"flex flex-wrap"}>
              {categories.map((cat) => (
                <CategoryBoxSelected
                  key={cat.catId}
                  add={false}
                  remove={true}
                  removeOnClick={() => handleRemoveCategory(cat)}
                  label={cat.catName}
                  vector={cat.catImage}
                  bgColor={"#481B3A"}
                  svgFill={"#FFFFFF"}
                />
              ))}
            </div>
            <Button
              sx={{
                border: 1,
                borderRadius: 4,
                borderColor: "#468C11",
                color: "#468C11",
                width: 89,
                mt: 1,
              }}
              onClick={handleOpenSelectCategoryModal}
            >
              <Add sx={{ color: "#468C11" }} /> Add
            </Button>
            <SelectCategoryModal
              open={openSelectCategoryModal}
              handleClose={handleCloseSelectCategoryModal}
              selectedCats={categories}
              handleAddCategory={handleAddCategory}
              handleDidNotFind={() => { }}
            /> */}
            {formErrors["categories"] && (
              <FormHelperText>{formErrors["categories"]}</FormHelperText>
            )}
          </FormControl>
          {/* <FormControl error={formErrors["categories"] ? true : false}>
            <FormLabel sx={formLableStyle}>Category</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {value.catName || (
                    <span className={"font-light text-plain-gray"}>
                      Select Category
                    </span>
                  )}
                </div>
              )}
              value={formValues.categories}
              onChange={(event) => {
                setFormValues((prevValues) => ({
                  ...prevValues,
                  categories: event.target.value,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {categoryOpts.map((categorygroup, idx) => (
                categorygroup.categories.map((category, idx) => (
                  <MenuItem value={category} key={idx}>
                    {category.catName}
                  </MenuItem>
                ))
              ))}
            </Select>
            {formErrors["categories"] && (
              <FormHelperText>{formErrors["categories"]}</FormHelperText>
            )}
          </FormControl> */}
          <FormControl error={formErrors["classDescription"] ? true : false}>
            <FormLabel sx={formLableStyle}>Description</FormLabel>
            <InputBase
              name={"classDescription"}
              placeholder={
                "Add description about this event and what would participants of this event would experience..."
              }
              value={formValues.classDescription}
              onChange={handleFormValueChange}
              multiline
              classes={formInputClasses}
              sx={{
                ...formInputStyle,
                minHeight: 116,
                maxHeight: 124,
                overflowY: "auto",
                flexWrap: "wrap",
              }}
            />
            {formErrors["classDescription"] && (
              <FormHelperText>{formErrors["classDescription"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={formErrors["duration"] ? true : false}>

            <FormLabel sx={formLableStyle}>Duration</FormLabel>
            <Select
              disableunderline="true"
              MenuProps={formSelectClasses}
              renderValue={(value) => (
                <div className={"flex flex-row items-center gap-3"}>
                  {/* <img src={clock} alt={""} /> */}
                  {value === "Select Duration" ? (
                    <span className={"font-light text-plain-gray"}>
                      Select Duration
                    </span>
                  ) : (
                    value
                  )}
                </div>
              )}
              name={"durationstring"}
              value={formValues.durationstring || "Select Duration"}
              onChange={(event) => {
                const durationSplit = event.target.value.split(" ");
                const durationInNum = Number(durationSplit[0]) * 60 + Number(durationSplit[2]);
                setFormValues((prevValues) => ({
                  ...prevValues,
                  durationstring: event.target.value,
                  duration: durationInNum,
                }));
              }}
              sx={{
                ...formInputStyle,
                py: 0.5,
              }}
            >
              {durationOpts.map((duration, idx) => (
                <MenuItem value={duration} key={idx}>
                  {duration}
                </MenuItem>
              ))}
            </Select>
            {formErrors["duration"] && (
              <FormHelperText>{formErrors["duration"]}</FormHelperText>
            )}
          </FormControl>
          <FormControl error={false}>
            <FormLabel sx={formLableStyle}>What to carry</FormLabel>
            <InputBase
              name={"whatToCarry"}
              // placeholder={"What to carry (Optional)"}
              value={formValues.whatToCarry}
              onChange={handleFormValueChange}
              inputProps={{ maxLength: 30 }}
              classes={formInputClasses}
              sx={formInputStyle}
            />
            {formErrors["whatToCarry"] && <FormHelperText></FormHelperText>}
          </FormControl>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ pt: 5 }}>
        <Button
          sx={{
            color: "secondary.mainLight",
            backgroundColor: "common.white",
            borderColor: "secondary.mainLight",
            border: 2,
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "secondary.mainLight",
              color: "common.white",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          onClick={() => {
            // clearFormValues();
            handleCancel();
            // dispatch(dashboardActions.setCalendarSessionId(-1));
            // dispatch(dashboardActions.setCalendarOpen(false));
            // navigate("/home/dashboard");
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "common.white",
            backgroundColor: "primary.main",
            boxShadow: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.main",
              boxShadow: "none",
            },
            fontWeight: 600,
            borderRadius: 3,
            width: 231,
            height: 52,
            fontSize: 16,
          }}
          variant={"contained"}
          disabled={isSubmitting}
          onClick={() => {
            if (edit) {
              handleUpdate();
            } else {
              handleSubmit();
            }
          }}
        >
          {edit ? "Update" : "Continue"}
        </Button>
      </Stack>
    </Stack>
  );
}
