import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    createNewClass: {},
    newClassRes: {},
    createNewCourse: {},
    newCourseRes: {},
    classToSchedule: {},
    courseToSchedule: {},
    classToEdit: {},
    courseToEdit: {},
    classToView: {},
    courseToView: {},
    locationToEdit: {},
    locationToView: {},
    viewSessionPage: false,
    calendarOpen: false,
    calendarSessionId: -1,
    calendarClassData: {},
    calendarSessionData: {},
    eventData: {},
    eventScheduleData: {},
    updateSessionComp: -1,
    updateHomeComp: -1,
    schoolData: {},
    serviceId: -1,
    venueId: -1,
    serviceBookingData: {},
    sessionPassData: {},
    resourceId: -1,
  },
  reducers: {
    setNewClassDetails: (state, action) => {
      state.createNewClass = { ...action.payload };
    },
    setNewClassRes: (state, action) => {
      state.newClassRes = { ...action.payload };
    },
    setNewCourseDetails: (state, action) => {
      state.createNewCourse = { ...action.payload };
    },
    setNewCourseRes: (state, action) => {
      state.newCourseRes = { ...action.payload };
    },
    setClassToSchedule: (state, action) => {
      state.classToSchedule = { ...action.payload };
    },
    setCourseToSchedule: (state, action) => {
      state.courseToSchedule = { ...action.payload };
    },
    setClassToEdit: (state, action) => {
      state.classToEdit = { ...action.payload };
    },
    setCourseToEdit: (state, action) => {
      state.courseToEdit = { ...action.payload };
    },
    setClassToView: (state, action) => {
      state.classToView = { ...action.payload };
    },
    setCourseToView: (state, action) => {
      state.courseToView = { ...action.payload };
    },
    setLocationToEdit: (state, action) => {
      state.locationToEdit = { ...action.payload };
    },
    setLocationToView: (state, action) => {
      state.locationToView = { ...action.payload };
    },
    setViewSessionPage: (state, action) => {
      state.viewSessionPage = action.payload;
    },
    setCalendarClassData: (state, action) => {
      state.calendarClassData = { ...action.payload };
    },
    setCalendarSessionData: (state, action) => {
      state.calendarSessionData = { ...action.payload };
    },
    setUpdateSessionComp: (state, action) => {
      state.updateSessionComp = action.payload;
    },
    setCalendarSessionId: (state, action) => {
      state.calendarSessionId = action.payload;
    },
    setUpdateHomeComp: (state, action) => {
      state.updateHomeComp = action.payload;
    },
    setSchoolData: (state, action) => {
      state.schoolData = { ...action.payload };
    },
    setCalendarOpen: (state, action) => {
      state.calendarOpen = action.payload;
    },
    setServiceId: (state, action) => {
      state.serviceId = action.payload;
    },
    setVenueId: (state, action) => {
      state.venueId = action.payload;
    },
    setServiceBookingData: (state, action) => {
      state.serviceBookingData = action.payload;
    },    
    setSessionPassData: (state, action) => {
      state.sessionPassData = action.payload;
    },
    setEventData: (state, action) => {
      state.eventData = { ...action.payload };
    },
    setEventScheduleData: (state, action) => {
      state.eventScheduleData = { ...action.payload };
    },
    clearDashboardData: (state, action) =>
    {
      const initialState = {
        createNewClass: {},
        newClassRes: {},
        createNewCourse: {},
        newCourseRes: {},
        classToSchedule: {},
        courseToSchedule: {},
        classToEdit: {},
        courseToEdit: {},
        classToView: {},
        courseToView: {},
        locationToEdit: {},
        locationToView: {},
        viewSessionPage: false,
        calendarOpen: false,
        calendarSessionId: -1,
        calendarClassData: {},
        calendarSessionData: {},
        updateSessionComp: -1,
        updateHomeComp: -1,
        schoolData: {},
        serviceId: -1,
        venueId: -1,
        serviceBookingData: {},
        sessionPassData: {},
      };
      Object.keys(initialState).forEach((key) =>
      {
        if(key === "viewSessionPage")
        {
          state[key] = false;
        }
        else if(key === "calendarSessionId")
        {
          state[key] = -1;
        }
        else if(key === "updateSessionComp")
        {
          state[key] = -1;
        }
        else if(key === "updateHomeComp")
        {
          state[key] = -1;
        }
        else if(key === "serviceId" || key === "venueId")
        {
          state[key] = -1;
        }
        else
        {
          state[key] = {};
        }
      })
    },
    setResourceId: (state, action) => {
      state.resourceId = action.payload;
    },
  },
});

export const {
  setNewClassDetails,
  setNewCourseDetails,
  setNewClassRes,
  setNewCourseRes,
  setClassToSchedule,
  setCourseToSchedule,
  setClassToEdit,
  setCourseToEdit,
  setClassToView,
  setCourseToView,
  setLocationToEdit,
  setLocationToView,
  setViewSessionPage,
  setCalendarOpen,
  setCalendarClassData,
  setCalendarSessionData,
  setEventData,
  setEventScheduleData,
  setUpdateSessionComp,
  setCalendarSessionId,
  setUpdateHomeComp,
  setSchoolData,
  setServiceId,
  setVenueId,
  setServiceBookingData,
  setSessionPassData,
  clearDashboardData,  
  setResourceId,
} = dashboardSlice.actions;

export const dashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;
