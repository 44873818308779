import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Autocomplete, Box, FormControl, InputAdornment, MenuItem, Popover, Select, Stack, styled, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses } from "@mui/material";
import axios from "axios";
import cogoToast from "cogo-toast";
import { addMinutes, format, parse, parseISO } from "date-fns";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Scroll from "react-scroll";
import EventIcon from '@mui/icons-material/Event';
import CachedIcon from '@mui/icons-material/Cached';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import CalendarView from "../../../components/CalendarView";
import ScheduleTile from "./ScheduleTile";

import { useLocalStorage } from "../../../hooks";
import * as dashboardActions from "../../../redux/reducers/dashboardReducer";
import { dashboardState } from "../../../redux/reducers/dashboardReducer";
import { userState } from "../../../redux/reducers/userReducer";


import { Button, TextField } from '@mui/material';
import NeewSidebar from "../../../components/NewSideBar";
import "../../../components/Sidebar.css";
import {
  LoadingBackdrop
} from "../../../layouts";

import moment from "moment";
import noServiceImage from "../../../assets/noEventImage.svg";
import AddEditFreeSlotModal from "../venue/AddEditFreeSlotModal";
import UserFreeTimeTile from "./UserFreeTimeTile";
import { getUrlForBookings, getUrlForServices, getUrlForUsers } from "../../../utils/confighelpers";
import customer_name from "../../../assets/customer_name.svg";
import dropdownbottom from "../../../assets/dropdownbottom.svg";
import AddNewAppointmentModal from "./AddNewAppointmentModal";
import { backgroundColor, borderColor, borderStyle } from "tailwindcss-classnames";
import { cutString } from "../../../helpers";
import FreeSlotPopover from "../venue/FreeSlotPopover";

const localizer = momentLocalizer(moment);

export default function ScheduleList({ navigation, profileFor, title }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardReduxState = useSelector(dashboardState);
  const [userToken] = useLocalStorage("userToken");
  const userReduxState = useSelector(userState);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [services, setServices] = React.useState();
  const [serviceDates, setServiceDates] = React.useState();
  const [serviceDatesRange, setServiceDatesRange] = React.useState();
  const [sessions, setSessions] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [userDetails, setUserDetails] = React.useState(false);
  const [searchVal, setSearchValues] = React.useState('');
  const [searchResourceId, setSearchResourceId] = React.useState('all');
  const [tabCurrentState, setTabCurrentState] = React.useState('1');
  const [serviceList, setServiceList] = React.useState([]);
  const [resourceList, setResourceList] = React.useState([]);
  const [createFreeSlotDialog, setCreateFreeSlotDialog] = React.useState({
    isEdit: false,
    open: false,
    text: "",
    data: {},
  });
  const [addNewAppointmentDialog, setAddNewAppointmentDialog] = React.useState({
    isEdit: false,
    open: false,
    text: "",
    data: {},
  });
  const [acionAnchorEl, setAcionAnchorEl] = React.useState(null);
  const [scheduleDisplayView, setScheduleDisplayView] = React.useState("calendar");
  const [resourceCalendarDate, setResourceCalendarDate] = React.useState(new Date(/* 2015, 3, 1 */));
  const [resourceCalendarScheduleList, setResourceCalendarScheduleList] = React.useState([]);
  const [scrollToTime, setScrollToTime] = React.useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const onNavigate = useCallback((newDate) => {
    setResourceCalendarDate(newDate)
  }, [setResourceCalendarDate])
  const onSelectEvent = useCallback((calEvent) => {
    if (calEvent.resource.itemType === "servieBookings") {
      handleSessionClick(calEvent.id, calEvent.resource);
    }
  }, [])
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => {
      return ({
        ...(isSelected && {
          style: {
            backgroundColor: '#000',
          },
        }),
        ...(event.resource.itemType === "servieBookings"
          && {
          className: 'rbc-servieBookings',
        }),
        ...(event.resource.itemType === "freeTime" && {
          className: 'rbc-freeTime',
        }),
        ...(event.resource.itemType === "availableFreeSlot" && {
          className: 'rbc-availableFreeSlot',
        }),
      })
    },
    []
  )

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setFirstLoad(true);
      await getUserInfo();
      await getListOfService();
      await getListOfSchedule();
      setFirstLoad(false);
      setTimeout(() => {

        setScrollToTime(new Date(new Date().setHours(10, 1)))
      }, 5000);
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, dashboardReduxState.updateSessionComp]);

  // React.useEffect(() => {
  //   setLoading(true);
  // }, []);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await getListOfSchedule();
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResourceId]);
  React.useEffect(() => {
    (async () => {
      await getCalendarScheduleOnDateChange(resourceCalendarDate, resourceCalendarDate);
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCalendarDate]);
  React.useEffect(() => {
    document.body.style.overflowY = ''
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (scheduleDisplayView === "calendar") {
      document.body.style.overflowY = 'hidden'
    }
  }, [scheduleDisplayView]);

  const onDateClickHandle = async (day) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(day), dateFormat);
    setSelectedDate(day);
    const newDate = new Date(day).toISOString();
    const config = {
      method: "get",
      // url: `${process.env.REACT_APP_API_URL_1}/service/booking?teacherId=${userReduxState.userData?._id}&groupby=date&searchDate=${newDate}&isSchedule=true`,
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&resourceId=${searchResourceId}&groupby=date&searchDate=${newDate}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInServices = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInServices)
          );
          // selectedDateSessions = filterObject(
          //   selectedDateSessions,
          //   (data) => Array.isArray(data) && data.length > 0
          // );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
  };

  const handleSessionClick = async (id, serviceBookingData) => {

    // cogoToast.loading("Loading session details " + id);
    cogoToast.loading("Loading...");
    dispatch(dashboardActions.setServiceBookingData(serviceBookingData));
    // navigate("/myservices/schedule/detail");
    navigate("/myservices/schedule/detail", {
      state: {
        bookingId: id
      },
    })
  };

  const renderSessions = (day, data, tab = "all") => {
    const dateFormat = "yyyy-MM-dd";
    const parsedDay = parse(day, dateFormat, new Date());
    const displayDateFormat = "EEEE, LLLL d";
    let newArr = [...data];
    // data = newArr.reverse();
    return (
      <Scroll.Element name={dateFormat} id={"sessionsPopover"} key={day}>
        <Scroll.Element name={day}>
          <p
            className={
              "font-bold text-black text-sm block self-start mt-5 mb-1"
            }
          >
            {format(parsedDay, displayDateFormat)}
          </p>
        </Scroll.Element>
        {data.length > 0 ? (
          data.map((item, idx) => {
            const dtStart = DateTime.fromISO(item.slot_date);
            const dtEnd = format(
              addMinutes(dtStart.ts, (item?.duration || 0)),
              "hh:mma"
            );
            let ticketQty = 1;
            const modifiedData = {
              id: item._id,
              item: item,
              // userDetails: userDetails,
            };
            return (
              tab === "all"
              || (tab === "published" && item.hasOwnProperty("isPublic") && item.isPublic === true)
              || (tab === "drafts" && item.hasOwnProperty("isPublic") && item.isPublic === false)
            ) ? (
              <div
                className={"cursor-pointer"}
              // onClick={() => handleSessionClick(modifiedData.id, item)}
              >
                {item.itemType === "servieBookings" ? (<ScheduleTile
                  {...modifiedData}
                  key={idx}
                  handleSessionClick={() => {
                    handleSessionClick(modifiedData.id, item);
                  }}
                />) : item.itemType === "freeTime" ? <UserFreeTimeTile
                  {...modifiedData}
                  key={idx}
                  handleSessionClick={() => {
                    // handleSessionClick(modifiedData.id, item);
                  }}
                  handleEditSlot={(data) => {
                    setCreateFreeSlotDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: true,
                      data: {
                        userDetails: userDetails,
                        id: data._id,
                        resourceId: data.resourceId,
                        isFullDay: data.isFullDay,
                        slotDates: data.slotDates,
                        slot_date: moment(data.slot_date, 'YYYY-MM-DD'),
                        slot_end_date: moment(data.slot_end_date, 'YYYY-MM-DD'),
                        slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                        slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                  handleDeleteSlot={async (data) => {
                    await addEditFreetime(data, "delete");
                  }}
                  loading={loading}
                  setLoading={setLoading}
                /> : <></>}
              </div>
            ) : "";
          })
        ) : (
          <div className={"text-black"}>No Sessions for this day check</div>
        )}
      </Scroll.Element>
    );
  };

  const addEditFreetime = async (slotDetails, action) => {
    cogoToast.loading("Loading...");
    setLoading(true);
    let body = {
      ...slotDetails,
      // slot_date: moment(slotDetails.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      // slot_date: slotDetails.slot_date,
      slot_start_time: moment(slotDetails.slot_start_time, "h:mm A").format("h:mm A"),
      slot_end_time: moment(slotDetails.slot_end_time, "h:mm A").format("h:mm A"),
      utcOffset: new Date().getTimezoneOffset(),
    };
    delete body.userDetails;
    delete body.slotDates;
    delete body.slot_end_date;
    if (body.isFullDay === true) {
      delete body.slot_start_time;
      delete body.slot_end_time;
    }
    let config = {
      method: "post",
      url: `${getUrlForUsers()}userfreetimes/add`,
      headers: {
        "x-access-token": userToken,
      },
      data: body,
    };
    if (action === "add") {
      config = {
        ...config,
        method: "post",
        url: `${getUrlForUsers()}userfreetimes/add`,
      }
    } else if (action === "edit") {
      config = {
        ...config,
        method: "post",
        url: `${getUrlForUsers()}userfreetimes/update`,
      }
    } else if (action === "delete") {
      config = {
        ...config,
        method: "delete",
        url: `${getUrlForUsers()}userfreetimes`,
        data: {
          id: body._id
        },
      }
    }
    const request = await axios(config);
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        setCreateFreeSlotDialog((prevValues) => ({
          ...prevValues,
          open: false,
        }));
        await getListOfSchedule();
        return true;
      } else {
        setCreateFreeSlotDialog((prevValues) => ({
          ...prevValues,
          // text: "You are not allowed to modify free slot, you have bookings available at this time",
          text: request.data.message,
        }));
        return true;
      }
    }
    setLoading(false);
    return true;
  }

  const addServiceBookings = async (slotDetails, action) => {
    cogoToast.loading("Loading...");
    setLoading(true);
    let serviceReservationsbody =
    {
      service_id: slotDetails.serviceId,
      venue_id: slotDetails.venue_id,
      resourceId: slotDetails.resourceId,
      priceType: slotDetails.priceType,
      slot_date: slotDetails.slot_date,
      slot_start_time: slotDetails.slot_start_time,
      slot_end_time: slotDetails.slot_end_time,
      duration: slotDetails.duration,
      utcOffset: new Date().getTimezoneOffset(),
    };
    const serviceReservationsRequest = await axios({
      method: "post",
      url: `${getUrlForBookings()}saveServiceReservations`,
      headers: {
        "x-access-token": userToken,
      },
      data: serviceReservationsbody,
    });
    if (serviceReservationsRequest.status === 200) {
      if (serviceReservationsRequest.data.statusCode === 200 && serviceReservationsRequest.data.data?.bookingsreservations?._id) {

        let bookingbody =
        {
          username: slotDetails.username,
          fullName: slotDetails.fullName,
          email: slotDetails.email,
          socialType: "normal",
          userType: "student",
          service_id: slotDetails.serviceId,
          venue_id: slotDetails.venue_id,
          resourceId: slotDetails.resourceId,
          priceType: slotDetails.priceType,
          slot_date: moment(slotDetails.slot_date).format("YYYY-MM-DD"),
          slot_start_time: slotDetails.slot_start_time,
          slot_end_time: slotDetails.slot_end_time,
          duration: slotDetails.duration,
          slotPrice: slotDetails.price,
          slotDescription: slotDetails.slotDescription,
          isreservations: false,
          bookingsReservationId: serviceReservationsRequest.data.data.bookingsreservations._id,
          ...slotDetails.price > 0 ? {
            paymentStatus: "Unpaid",
            paymentMode: "NA",
            paymentMethod: "payAtVenue"
          } : {
            paymentStatus: "Paid",
            paymentMode: "NA",
            paymentMethod: "payAtVenue"
          },
          utcOffset: new Date().getTimezoneOffset(),
        };
        let config = {
          method: "post",
          url: `${getUrlForBookings()}addServiceBookings`,
          headers: {
            "x-access-token": userToken,
          },
          data: bookingbody,
        };
        if (action === "add") {
          config = {
            ...config,
            method: "post",
            url: `${getUrlForBookings()}addServiceBookings`,
          }
        } else if (action === "edit") {
          config = {
            ...config,
            method: "post",
            url: `${getUrlForBookings()}addServiceBookings`,
          }
        }
        const request = await axios(config);
        if (request.status === 200) {
          if (request.data.statusCode === 200) {
            await sendBookingConfirmation({
              ...slotDetails,
              bookingData: request.data.data.bookingInfo
            });
            cogoToast.success("Appointment Booked Success!");
            setAddNewAppointmentDialog((prevValues) => ({
              ...prevValues,
              open: false,
            }));
            await getListOfSchedule();
          } else {
            setAddNewAppointmentDialog((prevValues) => ({
              ...prevValues,
              // text: "You are not allowed to modify free slot, you have bookings available at this time",
              // text: request.data.message,
              text: "Please check that your payment method is properly enabled.",
            }));
          }
        }
      } else {
        setAddNewAppointmentDialog((prevValues) => ({
          ...prevValues,
          // text: "You are not allowed to modify free slot, you have bookings available at this time",
          text: serviceReservationsRequest.data.message,
        }));
      }
    }
    setLoading(false);
    return true;
  }

  const sendBookingConfirmation = async (slotDetails) => {
    let serviceBookingConfirmationbody =
    {
      toName: slotDetails.fullName,
      toEmail: slotDetails.email,
      bookingType: "service",
      fromName: slotDetails?.serviceDetail?.teacher_detail?.fullName || "",
      notificationType: "confirmation",
      variables: {
        eventPhoto: slotDetails.serviceDetail.servicePhoto,
        eventName: slotDetails.serviceDetail.serviceName,
        eventDate: moment(slotDetails?.bookingData?.slot_date).format('dddd, MMMM Do'),
        eventTime: moment(slotDetails?.bookingData?.slot_date + " " + slotDetails?.bookingData?.slot_start_time, 'YYYY-MM-DD h:mm A').format('hh:mm A') + " - " + moment(slotDetails?.bookingData?.slot_date + " " + slotDetails?.bookingData?.slot_end_time, 'YYYY-MM-DD h:mm A').format('hh:mm A'),
        eventLocation: slotDetails.serviceDetail.venue_detail?.address?.addressName,
        eventAddress: slotDetails.serviceDetail.venue_detail?.address?.address,
        eventOrganizer: slotDetails?.resourceDetail?.fullName || slotDetails?.serviceDetail?.teacher_detail?.fullName || "",
        serviceFee: `${parseFloat(slotDetails?.bookingData?.totalPaymentServiceFee).toFixed(2)}`,
        totalPayableAmount: `${parseFloat(slotDetails?.bookingData?.totalStripeAmount).toFixed(2)}`,
        paymentMethod: `${slotDetails?.bookingData?.paymentMethod}`,
        bookingId: `${slotDetails?.bookingData?.bookingId}`,
        paymentStatus: `${slotDetails?.bookingData?.paymentStatus}`,
        manageBookingUrl: `${slotDetails?.bookingData?.manageBookingUrl}`,
        calendarURL: `${process.env.REACT_APP_BOOKING_LINK_SERVICES ? process.env.REACT_APP_BOOKING_LINK_SERVICES : "https://webshop.bookzyme.com"}/add-to-my-calender?eventName=${slotDetails.serviceDetail?.serviceName}&eventDate=${slotDetails?.bookingData?.slot_date}&time=${moment(slotDetails?.bookingData?.slot_date + " " + slotDetails?.bookingData?.slot_start_time, 'YYYY-MM-DD h:mm A').format('hh:mm A') + " - " + moment(slotDetails?.bookingData?.slot_date + " " + slotDetails?.bookingData?.slot_end_time, 'YYYY-MM-DD h:mm A').format('hh:mm A')}&eventLocation=${slotDetails.serviceDetail.venue_detail?.address?.addressName}`,
        mapUrl: `https://www.google.com/maps/dir//${slotDetails.serviceDetail.venue_detail?.address?.address}`,
        duration: slotDetails?.bookingData?.duration,
        bookingQty1: slotDetails?.bookingData?.servicesBought?.bookingQty,
        ticketType1: slotDetails?.bookingData?.servicesBought?.priceType,
        ticketTotalPrice1: slotDetails?.bookingData?.servicesBought?.totalPrice
      }
    };

    const serviceBookingConfirmationRequest = await axios({
      method: "post",
      url: `${getUrlForBookings()}sendBookingConfirmation`,
      headers: {
        "x-access-token": userToken,
      },
      data: serviceBookingConfirmationbody,
    });
    if (serviceBookingConfirmationRequest.status === 200) {
      if (serviceBookingConfirmationRequest.data.statusCode === 200 && serviceBookingConfirmationRequest.data.data?.bookingsreservations?._id) {
        // email sended

      } else {
        setAddNewAppointmentDialog((prevValues) => ({
          ...prevValues,
          // text: "You are not allowed to modify free slot, you have bookings available at this time",
          text: serviceBookingConfirmationRequest.data.message,
        }));
      }
    }
  }

  const getCalendarScheduleOnDateChange = async (startDate, endDate, serviceId = null) => {
    // const dateFormat = "yyyy-MM-dd";
    // const newDateFormated = format(new Date(day), dateFormat);
    const dateFormat = "YYYY-MM-DD";
    startDate = moment(startDate);
    endDate = moment(endDate);
    // const newDate = new Date(day).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&resourceId=${searchResourceId}&groupby=resource&startDate=${startDate.format(dateFormat)}&endDate=${endDate.format(dateFormat)}&isSchedule=true${serviceId ? `&serviceId=${serviceId}` : ""}`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSchedules;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSchedules = request.data.data;
        let getResourceCalendarScheduleList = []
        selectedDateSchedules.map((d) => {
          d.bookings.filter((booking) => {
            if (
              booking.resourceId
              && booking.resourceId !== ""
              // && booking.bookingOfStartDateTime
              // && booking.bookingOfEndDateTime
            ) {

              // let bookingOfStartDateTime = booking.bookingOfStartDateTime ? moment(booking.bookingOfStartDateTime) : moment(booking?.slot_date + " " + booking?.slot_start_time, 'YYYY-MM-DD h:mm A')
              // let bookingOfEndDateTime = booking.bookingOfEndDateTime ? moment(booking.bookingOfEndDateTime) : moment(booking?.slot_date + " " + booking?.slot_end_time, 'YYYY-MM-DD h:mm A')
              let bookingOfStartDateTime = moment(booking?.slot_date + " " + booking?.slot_start_time, 'YYYY-MM-DD h:mm A')
              let bookingOfEndDateTime = moment(booking?.slot_date + " " + booking?.slot_end_time, 'YYYY-MM-DD h:mm A')
              let title = ""
              if (booking?.itemType === "freeTime") {
                title = ((booking?.title || "Free time slot") + " - " + booking?.resource_detail.fullName)
              } else {
                title = booking?.student_detail.fullName + (booking?.service_detail?.serviceName ? " - " + booking.service_detail.serviceName : "")

              }
              let slotData = {
                id: booking._id,
                // title: `${bookingOfStartDateTime.format('H:mm')}-${bookingOfEndDateTime.format('H:mm')}: ${title}`,
                title: `${title}`,
                // start: new Date(bookingOfStartDateTime.format('YYYY-MM-DD h:mm A')),
                // end: new Date(bookingOfEndDateTime.format('YYYY-MM-DD h:mm A')),
                start: bookingOfStartDateTime.toDate(),
                end: bookingOfEndDateTime.toDate(),
                resourceId: booking.resourceId,
                resource: booking
              }
              getResourceCalendarScheduleList.push(slotData)
            }
            return booking.resourceId && booking.resourceId !== ""
          })

          return d
        });
        // console.log("getCalendarScheduleOnDateChange", selectedDateSchedules);
        // console.log("getResourceCalendarScheduleList", getResourceCalendarScheduleList);

        setResourceCalendarScheduleList(getResourceCalendarScheduleList);
      }
    }


  };


  const calendarOpenFromRedux = React.useMemo(() => {
    const bool = !loading && dashboardReduxState.calendarOpen;
    return bool;
  }, [loading, dashboardReduxState.calendarOpen]);

  const handleTabChange = (event, newValue) => {
    setTabCurrentState(newValue);
  };

  const getListOfService = async () => {
    let mgServices = [];
    const manageServicesConfig = {
      method: "get",
      url: `${getUrlForServices()}list?getall=true`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const manageServicesRequest = await axios(manageServicesConfig);
    if (manageServicesRequest.status === 200) {
      if (manageServicesRequest.data.data.length) {
        for (const item of manageServicesRequest.data.data) {
          mgServices.push({
            label: item.serviceName,
            id: item._id,
            data: item
          });
        }
        setServiceList(mgServices);
      }
    }
  };

  const getListOfSchedule = async () => {

    const date = new Date(selectedDate).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&resourceId=${searchResourceId || "all"}&groupby=date&searchDate=${date}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    if (request.status === 200) {
      const allServices = { ...request.data.data };
      let allServiceDates = [];
      const dateFormat = "yyyy-MM-dd";
      const serviceDatesRange = { from: "", to: "" };
      const serviceKeys = Object.keys(allServices);
      const startDate = parse(serviceKeys[0], dateFormat, new Date());
      const endDate = parse(
        serviceKeys[serviceKeys.length - 1],
        dateFormat,
        new Date()
      );
      serviceDatesRange.from = startDate;
      serviceDatesRange.to = endDate;
      serviceKeys.forEach((key) => {
        if (allServices[key].length > 0) {
          const parsedDate = parse(key, dateFormat, new Date());
          allServiceDates.push(parsedDate);
        }
      });
      setServiceDatesRange({ ...serviceDatesRange });
      setServiceDates([...allServiceDates]);
      setServices({ ...allServices });

      let initialSessionsData = { ...allServices };
      const todayFormated = format(new Date(selectedDate), dateFormat);
      const idxOfTodayInServices =
        Object.keys(initialSessionsData).indexOf(todayFormated);
      initialSessionsData = Object.fromEntries(
        Object.entries(initialSessionsData).slice(idxOfTodayInServices)
      );

      // initialSessionsData = filterObject(
      //   initialSessionsData,
      //   (data) => Array.isArray(data) && data.length > 0
      // );

      setSessions({ ...initialSessionsData });
      setLoading(false);
    }
  };

  const onSearchClickHandle = async (serviceData) => {
    const dateFormat = "yyyy-MM-dd";
    const newDateFormated = format(new Date(selectedDate), dateFormat);
    const newDate = new Date(selectedDate).toISOString();
    const config = {
      method: "get",
      url: `${getUrlForBookings()}getServiceBooking?teacherId=${userReduxState.userData?._id}&resourceId=${searchResourceId}&groupby=date&searchDate=${newDate}&serviceId=${serviceData.id}&isSchedule=true`,
      headers: {
        "x-access-token": userToken.toString(),
      },
    };
    const request = await axios(config);
    let selectedDateSessions;
    if (request.status === 200) {
      if (request.data.statusCode === 200) {
        selectedDateSessions = request.data.data;
        if (!Object.keys(selectedDateSessions).length) {
          const formattedDate = format(parseISO(newDate), dateFormat);
          selectedDateSessions = { [formattedDate]: [] };
        } else {
          const idxOfNewDateInServices = Object.keys(request.data.data).indexOf(
            newDateFormated
          );
          selectedDateSessions = Object.fromEntries(
            Object.entries(request.data.data).slice(idxOfNewDateInServices)
          );
        }
      }
    }

    setSessions({ ...selectedDateSessions });

    const { scroller } = Scroll;
    scroller.scrollTo(newDateFormated, {
      smooth: true,
      containerId: "sessionsPopover",
      delay: 2000,
    });
    await getCalendarScheduleOnDateChange(resourceCalendarDate, resourceCalendarDate, serviceData.id);
  };

  const getUserInfo = async () => {
    let mgResource = [];
    const config = {
      method: "get",
      url: `${getUrlForUsers()}getUserInfo`,
      headers: {
        "x-access-token": userToken,
      },
    };
    const request = await axios(config);
    if (request.data.statusCode === 200) {
      setUserDetails(request.data.data);
      mgResource.push({
        label: "All Resource",
        id: "all",
        data: {}
      });
      if (request.data.data.myResource.length) {
        for (const item of request.data.data.myResource) {
          let currentDay = moment(resourceCalendarDate).format('dddd')
          let selectOpenDayAndTime = item.openDayAndTime.find((d) => d.day === currentDay.toLowerCase());
          let mgResourceData = {
            label: item.fullName,
            id: item._id,
            resourceId: item._id,
            data: {
              ...item,
              openTime: selectOpenDayAndTime.openTime || [],
            }
          }

          mgResource.push(mgResourceData);
        }
        setResourceList(mgResource);
      }
    }
  }


  const formLableStyle = {
    my: 1,
    color: "rgba(0, 0, 0, 0.6);"
  };

  const formInputStyle = {
    p: 2,
    border: 1,
    borderRadius: 3,
    borderColor: "#CCCCCC",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    // minWidth: 455,
    // maxWidth: 455,
    "&:hover, &.Mui-focused": {
      border: 1,
      borderColor: "#7E00DD"
    },
    '.MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const formInputClasses = {
    input: {
      "&::placeholder": {
        color: "#808080",
        fontWeight: 300,
      },
    },
  };

  const formSelectClasses = {
    sx: {
      "& .MuiMenu-paper": {
        border: 1,
        borderColor: "#EBEBEB",
        borderRadius: 3,
        maxHeight: 250,
        mt: 1,
      },
      "&:hover, &.Mui-focused": {
        border: 1,
        borderColor: "#7E00DD"
      },
    },
  };

  const handleActionClick = (event) => {
    setAcionAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAcionAnchorEl(null);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
      margin: 0,
      border: 1,
      borderStyle: "solid",
      borderColor: "#FF5733",
      // borderRadius: 4,
      // backgroundColor: "#000",
      [`&.${toggleButtonGroupClasses.disabled}`]: {
        border: 0,
      },
      [`&.${toggleButtonGroupClasses.selected}`]: {
        border: 0,
        color: "#fff",
        backgroundColor: "#FF5733",
      },
    },
  }));

  const popoverActionOpen = Boolean(acionAnchorEl);
  const actionId = popoverActionOpen ? 'simple-popover' : undefined;


  return (
    <NeewSidebar
      navBarTitleComp={
        <h3 className={"font-extrabold text-2xl text-brown-coffee"}>
          {title}
        </h3>
      }
      subHeader={
        <div className={"flex gap-3"}>
          <div className={"w-[47.5%] flex gap-3 pl-6"}>
            {serviceList.length > 0 && (<Autocomplete
              disablePortal
              options={serviceList}
              value={searchVal}
              onChange={async (event, newValue) => {
                if (newValue) {
                  setSearchValues(newValue.label);
                  onSearchClickHandle(newValue);
                } else {
                  setSearchValues("");
                  // onSearchClickHandle({ label: "", id: "", data: {} });
                  await getListOfSchedule();
                }
              }}
              sx={{
                borderColor: "#EBEBEB",
                borderRadius: "10px",
              }}
              // renderInput={(params) => <TextField {...params} label="Movie" />}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={"All services..."}
                    value={searchVal}
                    sx={{
                      borderColor: "#EBEBEB",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        borderColor: "#EBEBEB",
                      },
                    }}
                  />
                );
              }}
              className="w-full"
            />)}
            {resourceList.length > 0 && (<Autocomplete
              disablePortal
              options={resourceList}
              value={searchResourceId !== "" ? resourceList.find((d) => d.id == searchResourceId).label : ""}
              onChange={async (event, newValue) => {
                if (newValue) {
                  // setSearchResourceId(newValue.label);
                  setSearchResourceId(newValue.id);
                  // onSearchClickHandle(newValue);
                } else {
                  setSearchResourceId("");
                  // onSearchClickHandle({ label: "", id: "", data: {} });
                  // await getListOfSchedule();
                }
              }}
              sx={{
                borderColor: "#EBEBEB",
                borderRadius: "10px",
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    placeholder={"All Resources..."}
                    value={searchResourceId !== "" ? resourceList.find((d) => d.id == searchResourceId).label : "All Resources"}
                    sx={{
                      borderColor: "#EBEBEB",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        borderColor: "#EBEBEB",
                      },
                    }}
                  />
                );
              }}
              className="w-full"
            />)}
          </div>
          <div className={"flex flex-row items-center mr-28 gap-3"}>
            <button
              className={"bg-[#3699FF] text-white rounded w-[160px] p-4"}
              onClick={handleActionClick}
            >
              <span className={"flex flex-row gap-2  items-center"}>
                <p className={"w-full"}>+ Add new</p>
                <img src={dropdownbottom} alt={""} />
              </span>
            </button>
            <Popover
              id={actionId}
              open={popoverActionOpen}
              anchorEl={acionAnchorEl}
              onClose={handleActionClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className={"flex flex-col gap-3 py-5"}>
                <button
                  className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${false
                    ? ""
                    : "active:bg-light-brown-coffee active:text-white"
                    } disabled:opacity-60 disabled:cursor-default`}
                  onClick={() => {

                    handleActionClose()
                    setAddNewAppointmentDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: false,
                      data: {
                        fullName: "",
                        username: "",
                        email: "",
                        serviceList: serviceList,
                        userDetails: userDetails,
                        slot_date: moment(),
                        // slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                        // slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                        slot_start_time: "",
                        slot_end_time: "",
                        // serviceId: "",
                        venue_id: "",
                        resourceId: "",
                        duration: 0,
                        priceType: "",
                        slotDates: [],
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                >
                  <p className={"text-sm"}>Appointment</p>
                </button>
                <button
                  className={`flex flex-row justify-between items-center gap-4 cursor-pointer px-8 py-2 transition-all ${false
                    ? ""
                    : "active:bg-light-brown-coffee active:text-white"
                    } disabled:opacity-60 disabled:cursor-default`}
                  onClick={() => {
                    handleActionClose()
                    setCreateFreeSlotDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: false,
                      data: {
                        userDetails: userDetails,
                        // slot_date: moment().format("YYYY-MM-DD"),
                        // slot_date: moment(),
                        resourceId: "",
                        title: "",
                        slot_date: "",
                        // slot_end_date: moment(),
                        slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                        slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                        isFullDay: false,
                        slotDates: [],
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                >
                  <p className={"text-sm"}>Free time</p>
                </button>
              </div>
            </Popover>
            <StyledToggleButtonGroup
              // orientation="vertical"
              value={scheduleDisplayView}
              exclusive
              onChange={(event, nextView) => {
                setScheduleDisplayView(nextView);
              }}
            >
              <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="calendar" aria-label="calendar">
                <EventIcon />
              </ToggleButton>
            </StyledToggleButtonGroup>
            <button
              className={"cursor-pointer bg-[#FFF] hover:bg-[#0000000a] border border-bright-gray rounded p-3"}
              onClick={async () => {
                setLoading(true);
                await getListOfSchedule();
                setLoading(false);
              }}
            >
              <CachedIcon />
            </button>
          </div>
        </div>
      }
      children={<LoadingBackdrop loading={firstLoad || loading}>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            py: 2,
            px: 3,
            overflowX: "hidden",
          }}
          // className="grid justify-items-center"
          className="grid"  >

          {firstLoad === false && (serviceList.length ? (scheduleDisplayView === "calendar" ? (
            <div
              className={"w-full px-3"}
              // style={{ height: "67vh" }}
              style={{ height: (window.innerHeight - document.getElementById("bk-appbar").offsetHeight - 45) + "px" }}
            ><Calendar
                selectable={false}
                onSelecting={slot => false}
                localizer={localizer}
                events={resourceCalendarScheduleList}
                resources={(searchResourceId && searchResourceId !== "all" && searchResourceId !== "") ? resourceList.filter((d) => d.id === searchResourceId) : resourceList.filter((d) => d.id !== "all")} // Ensure resources are passed correctly
                resourceIdAccessor="resourceId" // Correct reference for resourceId
                resourceTitleAccessor="fullName" // Correct reference for the resource title (name)
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                // style={{ height: 1000 }}
                views={["day"]}
                defaultView="day"
                step={15} // Larger time slots
                timeslots={1}
                dateFormat="h t"
                components={{
                  resourceHeader: ({ resource }) => {
                    return (
                      <div
                        className="flex flex-row gap-2 text-center justify-center items-center p-2"
                      >
                        <img
                          className={"w-[45px] h-[45px] rounded-full object-cover"}
                          src={resource.data.profilePic || "https://bmcapp.s3.eu-west-1.amazonaws.com/assets/DefaultAvatar.png"} // Display resource profile image
                          alt={resource.data.fullName}
                        />
                        <div>{resource.data.fullName}</div> {/* Display resource name */}
                      </div>
                    )
                  },
                  event: ({ event }) => {
                    // Format the time for display
                    const startTime = moment(event.start).format("h:mm A");
                    const endTime = moment(event.end).format("h:mm A");
                    let bookingDetail = event.resource;
                    if (bookingDetail?.itemType === "freeTime") {
                      return (<div
                        className={`flex flex-col gap-1 text-start justify-start items-start 1-bg[${event.color}]`}
                      >
                        <div className="text-lg">
                          {bookingDetail?.title || "Free time slot"} - {bookingDetail?.resource_detail?.fullName || ""}
                        </div>
                        <div className="text-lg">
                          {event.resource.itemType === "freeTime" && (<div className={"flex items-center text-[#5B274B] font-bold text-base"}>
                            <FreeSlotPopover
                              slotDetails={{ ...bookingDetail }}
                              handleEditSlot={(data) => {
                                setCreateFreeSlotDialog((prevValues) => ({
                                  ...prevValues,
                                  isEdit: true,
                                  data: {
                                    userDetails: userDetails,
                                    id: data._id,
                                    resourceId: data.resourceId,
                                    isFullDay: data.isFullDay,
                                    slotDates: data.slotDates,
                                    slot_date: moment(data.slot_date, 'YYYY-MM-DD'),
                                    slot_end_date: moment(data.slot_end_date, 'YYYY-MM-DD'),
                                    slot_start_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_start_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                                    slot_end_time: moment(moment(data.slot_date, 'YYYY-MM-DD').format('YYYY-MM-DD') + " " + data.slot_end_time, 'YYYY-MM-DD h:mm A').format("h:mm A"),
                                  },
                                  text: "",
                                  open: true,
                                }));
                              }}
                              handleDeleteSlot={async (data) => {
                                await addEditFreetime(data, "delete");
                              }}
                              loading={loading}
                              setLoading={setLoading}
                            />
                          </div>)}
                        </div>
                      </div>);
                    } else if (bookingDetail?.itemType === "servieBookings") {
                      return <div
                        className={`flex flex-col gap-1 text-start justify-start items-start 1-bg[${event.color}]`}
                      >
                        <div className={`flex ${bookingDetail?.duration != 15 ? "text-lg" : "text-sm"}`}>
                          <div className="flex-1">{bookingDetail.student_detail.fullName}{bookingDetail?.duration == 15 ? " - " + bookingDetail.service_detail.serviceName : ""}</div>
                        </div>
                        {bookingDetail?.duration != 15 && (<div className="text-sm" onClick={() => { /* handleSessionClick(event.id, event.resource); */ }}>
                          {bookingDetail.service_detail.serviceName}
                        </div>)}
                      </div>;
                    } else if (bookingDetail?.itemType === "availableFreeSlot") {
                      return (<div
                        className={`flex flex-col gap-1 text-start justify-start items-start 1-bg[${event.color}]`}
                      >
                        <div className="flex text-lg">
                          <div className="flex-1">{event.title}</div>
                        </div>
                      </div>);
                    } else {
                      return <div
                        className={`flex flex-col gap-1 text-start justify-start items-start 1-bg[${event.color}]`}
                      >
                        <div className="flex text-lg">
                          <div className="flex-1">{event.title}</div>
                        </div>
                      </div>;
                    }
                  },
                }}
                min={new Date(2024, 1, 0, 7, 0, 0)}
                // max={new Date(2024, 1, 0, 22, 0, 0)}
                date={resourceCalendarDate}
                scrollToTime={scrollToTime && new Date(scrollToTime)}
                onNavigate={onNavigate}
                selectable={true}
                onSelectEvent={onSelectEvent}
                eventPropGetter={eventPropGetter}
                formats={{
                  timeGutterFormat: 'HH:mm',
                }}
              /></div>) : (<div
                className={"grid grid-cols-2 w-full px-3 pt-5 pt-0"}
              >
                <div className={"flex flex-col justify-center items-center"}>
                  {false && (<><Autocomplete
                    disablePortal
                    options={serviceList}
                    value={searchVal}
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        setSearchValues(newValue.label);
                        onSearchClickHandle(newValue);
                      } else {
                        setSearchValues("");
                        // onSearchClickHandle({ label: "", id: "", data: {} });
                        await getListOfSchedule();
                      }
                    }}
                    sx={{
                      borderColor: "#EBEBEB",
                      borderRadius: "10px",
                    }}
                    // renderInput={(params) => <TextField {...params} label="Movie" />}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder={"Search..."}
                          value={searchVal}
                          sx={{
                            borderColor: "#EBEBEB",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              borderColor: "#EBEBEB",
                            },
                          }}
                        /* endAdornment={
                          <InputAdornment position={"end"}>
                            <img
                              className={"lg:w-[18px] w-[18px]"}
                              src={search_png}
                              alt={""}
                            />
                          </InputAdornment>
                        } */
                        // endAdornment={InputProps?.endAdornment}
                        />
                      );
                    }}
                    className="w-full pr-7"
                  />
                    <FormControl className="w-full mt-2">
                      <Select
                        disableunderline="true"
                        startAdornment={
                          <InputAdornment position={"start"}>
                            <img className={"w-6"} src={customer_name} alt={""} />
                          </InputAdornment>
                        }
                        renderValue={(value) => (
                          <div className={"flex flex-row items-center gap-3"} style={{ textTransform: 'capitalize' }}>
                            {value?._id ? value?.fullName : (
                              <span className={"font-light text-plain-gray"}>
                                Select Resource
                              </span>
                            )}
                          </div>
                        )}
                        name={"searchResourceId"}
                        value={userDetails?.myResource.find((d) => d._id == searchResourceId) || { _id: "all", fullName: "All", }}
                        onChange={(e) => {
                          setSearchResourceId(e.target.value._id)
                        }}
                        MenuProps={formSelectClasses}
                        sx={{
                          ...formInputStyle,
                          py: 0.5,
                          // minWidth: 627,
                          borderRadius: "10px",
                        }}
                        className="mr-7 mt-3"
                      >
                        <MenuItem value={{ _id: "all", fullName: "All", }} key={-1}>All</MenuItem>
                        {userDetails?.myResource.map((d, i) => {
                          return (<MenuItem value={d} key={i}>{d.fullName}</MenuItem>)
                        })}
                      </Select>
                    </FormControl></>)}
                  <AppBar
                    position={"sticky"}
                    elevation={0}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      py: 2,
                      pt: 0,
                      px: 0,
                      pr: 3.5,
                    }}
                  >
                    <Stack direction={"column"} alignItems={"center"}>
                      <CalendarView
                        selectedDate={selectedDate}
                        onDateClickHandle={onDateClickHandle}
                        eventDatesRange={serviceDatesRange}
                        eventDates={serviceDates}
                      />
                    </Stack>
                  </AppBar>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    {<TabContext value={tabCurrentState}>
                      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" sx={{
                      fontSize: 18
                    }}>
                      <Tab label="All" value="1" />
                      <Tab label="Published" value="2" />
                      <Tab label="Drafts" value="3" />
                      <Tab label="Archived" value="4" />
                    </TabList>
                  </Box> */}
                      <TabPanel value="1" sx={{ p: 2 }}>
                        <div className={`self-start px-3`}>
                          {Object.entries(sessions).map(([key, value]) =>
                            renderSessions(key, value, "all")
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="2">
                        <div className={`self-start px-3`}>
                          {Object.entries(sessions).map(([key, value]) =>
                            renderSessions(key, value, "published")
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="3">
                        <div className={`self-start px-3`}>
                          {Object.entries(sessions).map(([key, value]) =>
                            renderSessions(key, value, "drafts")
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel value="4">Archived</TabPanel>
                    </TabContext>}
                  </Box>
                </div>
                <div className={"flex justify-end items-top"}>
                  <div className={"flex flex-row items-start mr-28 gap-2 hidden"}>
                    {/* <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "secondary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    navigate("/myservices/new");
                  }}
                >
                  Add New Service
                </Button> */}
                    {/* <Button
                  sx={{
                    color: "common.white",
                    backgroundColor: "primary.main",
                    boxShadow: "none",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      boxShadow: "none",
                    },
                    fontWeight: 600,
                    borderRadius: 3,
                    width: 231,
                    height: 52,
                    fontSize: 16,
                  }}
                  variant={"contained"}
                  onClick={() => {
                    setCreateFreeSlotDialog((prevValues) => ({
                      ...prevValues,
                      isEdit: false,
                      data: {
                        userDetails: userDetails,
                        // slot_date: moment().format("YYYY-MM-DD"),
                        // slot_date: moment(),
                        resourceId: "",
                        title: "",
                        slot_date: "",
                        // slot_end_date: moment(),
                        slot_start_time: moment("9:00 AM", "h:mm A").format("h:mm A"),
                        slot_end_time: moment("10:00 AM", "h:mm A").format("h:mm A"),
                        isFullDay: false,
                        slotDates: [],
                      },
                      text: "",
                      open: true,
                    }));
                  }}
                >
                  Add Free time
                </Button> */}
                  </div>
                </div>
              </div>)) : (loading === false ? (
                <Box className="flex flex-col justify-center items-center w-full mt-28">

                  <Box className="flex flex-col justify-center items-center text-center">
                    <img
                      className={"w-[235px]"}
                      src={noServiceImage}
                      alt={""}
                    />
                    <Box sx={{
                      color: "#481B3A",
                      fontSize: "18px",
                      fontWeight: "700",
                      mt: 3,
                    }}>
                      No Services Configured
                    </Box>
                  </Box>
                  <Box className="flex justify-center items-center w-full mt-5">
                    <Button
                      sx={{
                        color: "common.white",
                        backgroundColor: "secondary.main",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                          boxShadow: "none",
                        },
                        fontWeight: 600,
                        borderRadius: 3,
                        width: 231,
                        height: 52,
                        fontSize: 16,
                      }}
                      variant={"contained"}
                      onClick={() => {
                        navigate("/myservices/new");
                      }}
                    >
                      Add  a new  Service
                    </Button>
                  </Box>
                </Box>
              ) : ""))}
          <AddEditFreeSlotModal
            isEdit={createFreeSlotDialog.isEdit}
            loading={loading}
            text={createFreeSlotDialog.text || ""}
            open={createFreeSlotDialog.open}
            data={createFreeSlotDialog.data}
            onClose={() => {
              setCreateFreeSlotDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
            onSubmit={async (data) => {
              if (createFreeSlotDialog.isEdit) {
                // edit api
                await addEditFreetime(data, "edit");
              } else {
                // add api
                await addEditFreetime(data, "add");
              }
            }}
          />
          <AddNewAppointmentModal
            isEdit={addNewAppointmentDialog.isEdit}
            loading={loading}
            text={addNewAppointmentDialog.text || ""}
            open={addNewAppointmentDialog.open}
            setAddNewAppointmentDialog={setAddNewAppointmentDialog}
            data={addNewAppointmentDialog.data}
            onClose={() => {
              setAddNewAppointmentDialog((prevValues) => ({
                ...prevValues,
                open: false,
              }));
            }}
            onSubmit={async (data) => {
              if (addNewAppointmentDialog.isEdit) {
                // edit api
                await addServiceBookings(data, "edit");
              } else {
                // add api
                await addServiceBookings(data, "add");
              }
            }}
          />
        </Box>
      </LoadingBackdrop>} />
  );
}
